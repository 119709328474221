import React from 'react';
import { Route } from 'react-router-dom';
import {
	MANUFACTURE_PRODUCTION_WORKSHOPS_PATH,
	MANUFACTURE_EQUIPMENTS_PATH,
	MANUFACTURE_PRODUCTION_PROCESSES_PATH,
	MANUFACTURE_MATERIALS_PATH,
	MANUFACTURE_TECH_MAP_PATH,
	MANUFACTURE_SETTINGS_PATH,
	MANUFACTURE_PRODUCTION_ORDERS_PATH,
	MANUFACTURE_REPORTS_PATH,
	MANUFACTURE_DASHBOARD_PATH,
} from '@pages/constants';
import t from 'i18n';
import { SwitchWith404 } from '@widgets/PageNotFound/SwitchWith404';
import loadable from '@loadable/component';
import { withTitleAndLink } from 'client/tools/hoc/withTitleAndLink';
const Workshops = loadable(() => import('./workshops/workshop'));
const Equipments = loadable(() => import('./equipments'));
const Processes = loadable(() => import('./processes'));
const Materials = loadable(() => import('./materials'));
const TechMaps = loadable(() => import('./tech-maps'));
const Productions = loadable(() => import('./production-orders'));
const Reports = loadable(() => import('./reports'));
const Settings = loadable(() => import('./settings'));
const Dashboard = loadable(() => import('./dashboard'));

/**
 * Главный компонент с роутами и компонентами модуля.
 * Также может содержать, например, некую асинхронную логику инициализации.
 */

export default () => (
	<SwitchWith404>
		<Route path={MANUFACTURE_DASHBOARD_PATH} component={withTitleAndLink(Dashboard, { title: t('dashBoard') })} />
		<Route path={MANUFACTURE_PRODUCTION_ORDERS_PATH} component={Productions} />
		<Route path={MANUFACTURE_PRODUCTION_WORKSHOPS_PATH} component={Workshops} />
		<Route path={MANUFACTURE_EQUIPMENTS_PATH} component={Equipments} />
		<Route path={MANUFACTURE_PRODUCTION_PROCESSES_PATH} component={Processes} />
		<Route path={MANUFACTURE_MATERIALS_PATH} component={Materials} />
		<Route path={MANUFACTURE_TECH_MAP_PATH} component={TechMaps} />
		<Route path={MANUFACTURE_REPORTS_PATH} component={withTitleAndLink(Reports, { title: t('reports.plural') })} />
		<Route path={MANUFACTURE_SETTINGS_PATH} component={withTitleAndLink(Settings, { title: t('settings') })} />
	</SwitchWith404>
);
