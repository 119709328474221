import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { TextField } from '@consta/uikit/TextField';
import { Text } from '@consta/uikit/Text';
import ArrowClockwise from '@phosphor-icons/core/regular/arrow-clockwise.svg';
import './captcha.scss';
import t from 'i18n';

@observer
export class Captcha extends Component {
	render() {
		const { value, onKeyUp, onChange, inputRef, error, time, reloads, refresh } = this.props;
		return (
			<div className='captcha-wrapper'>
				<TextField
					className='field'
					type='text'
					name='captcha'
					required
					onKeyUp={onKeyUp}
					value={value}
					onChange={onChange}
					inputRef={inputRef}
					label={t('auth.captcha')}
					caption={error && (<Text view='alert'>{error}</Text>)}
				/>
				<div className='captcha'>
					<img src={`/api/captcha?new-${time}-${reloads}`} alt='' />
					<ArrowClockwise view='success' onClick={refresh} />
				</div>
			</div>
		);
	}
}

