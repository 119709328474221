import React from 'react';
import { Route } from 'react-router-dom';
import {
	STOREHOUSE_NOMENCLATURES_PATH,
	STOREHOUSE_WAREHOUSES_PATH,
	STOREHOUSE_NOMENCLATUREMOVEMENTS_PATH,
	STOREHOUSE_SETTINGS_PATH,
	STOREHOUSE_REPORTS_PATH,
} from '@pages/constants';
import loadable from '@loadable/component';
import { withTitleAndLink } from 'client/tools/hoc/withTitleAndLink';
import { SwitchWith404 } from '@widgets/PageNotFound/SwitchWith404';
const Nomenclatures = loadable(() => import('./nomenclatures'));
const Movements = loadable(() => import('./nomenclatureMovements'));
const Warehouses = loadable(() => import('./warehouses'));
const Reports = loadable(() => import('./reports'));
const Settings = loadable(() => import('./settings'));
import t from 'i18n';

/**
 * Главный компонент с роутами и компонентами модуля.
 * Также может содержать, например, некую асинхронную логику инициализации.
 */

export default () => (
	<SwitchWith404>
		<Route path={STOREHOUSE_NOMENCLATURES_PATH} component={Nomenclatures} />
		<Route path={STOREHOUSE_WAREHOUSES_PATH} component={Warehouses} />
		<Route path={STOREHOUSE_NOMENCLATUREMOVEMENTS_PATH} component={Movements} />
		<Route path={STOREHOUSE_REPORTS_PATH} component={Reports} />
		<Route path={STOREHOUSE_SETTINGS_PATH} component={withTitleAndLink(Settings, { title: t('settings') })} />
	</SwitchWith404>
);
