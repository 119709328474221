import React, { Component } from 'react';
import classNames from 'classnames';
import { ChoiceGroup } from '@consta/uikit/ChoiceGroup';
import { Text } from '@consta/uikit/Text';

export class ToolbarButtonGroup extends Component {
	render() {
		const { items, onChange, value, className, label, getItemLabel } = this.props;
		const _className = classNames('closed-filters', className);

		return <ChoiceGroup className={_className} value={value} onChange={onChange} items={items} getItemLabel={getItemLabel} />;
	}
}

