import React, { Component, createRef } from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { IconButton, Section, Button, CancelButton, Editor } from 'components';
import store from 'client/store';
import t from 'i18n';
import classNames from 'classnames';
import { renderFaIcons, processText } from 'client/tools';
import { AutoAnimate } from '@smartplatform/consta/ui/AutoAnimate';

@inject('productStore')
@observer
export class Description extends Component {
	@observable isContentHide = false;
	@observable isEdit = false;

	descriptionContent;

	constructor(props) {
		super(props);
		this.store = props.productStore;
		this.product = this.store.product;
		this.ref = createRef();
	}

	toggleEditDescription = () => {
		this.isEdit = !this.isEdit;
	};

	onSaveDescription = async () => {
		await this.product.patchAttributes({ description: this.product.description });
		this.toggleEditDescription();
	};

	onChange = (value) => {
		this.product.description = value;
	};

	onCollapseSection = () => {
		this.isContentHide = !this.isContentHide;
	};

	render() {
		const { product } = this;

		return (
			<Section title={t('product.description')}>
				<AutoAnimate>
					{!this.isContentHide && (
						<div className={classNames('description')}>
							<div>
								{this.isEdit || !product.id ? (
									<>
										<Editor value={product.description} onChange={this.onChange} />
										<div className='toggle'>
											{product.id && (
												<>
													<Button text={t('save')} onClick={this.onSaveDescription} />
													<CancelButton onClick={this.toggleEditDescription} />
												</>
											)}
										</div>
									</>
								) : (
									<>
										<div
											className='rich-text'
											dangerouslySetInnerHTML={{
												__html: processText(
													product.description?.length > 0 ? product.description : t('product.addDescription')
												),
											}}
										/>
										<div className='toggle'>
											<Button
												text={t('changeDescription')}
												onClick={this.toggleEditDescription}
												startIcon={renderFaIcons.editIcon}
												variant='default'
											/>
										</div>
									</>
								)}
							</div>
						</div>
					)}
				</AutoAnimate>
			</Section>
		);
	}
}

