import './Status.scss';
import { Text } from '@consta/uikit/Text';
const defautColor = 'grey';

export const Status = ({ color, name, truncate, size }) => {
	return (
		<div className='Status'>
			<span style={{ background: color || defautColor }} />
			<Text size={size} truncate={truncate}>
				{name}
			</Text>
		</div>
	);
};
