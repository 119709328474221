import React from 'react';
import { observer } from 'mobx-react';
import { Button } from '@consta/uikit/Button';
import ExportIcon from '@phosphor-icons/core/regular/file-arrow-up.svg';
import { addResourceBundle } from './i18n';
import { useTranslation } from 'react-i18next';

addResourceBundle();

export const ExportButton = observer(({ view = 'secondary', label, ...restButtonProps }) => {
	const { t } = useTranslation('components', { keyPrefix: 'ExportButton' });

	if (!label) {
		label = t('export');
	}
	return <Button {...restButtonProps} iconRight={ExportIcon} label={label} view={view} />;
});
