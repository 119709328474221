import React from 'react';
import { observer } from 'mobx-react';
import { StatusSelect, renderStatus } from '@features/Status';
import t from 'i18n';
import classnames from 'classnames';
import './ToolbarListSelect.scss';

@observer
export class ToolbarListSelect extends React.Component {
	defaultProps = {
		size: 'm',
	};
	constructor(props) {
		super(props);
		this.placeholder = props.placeholder;
		if (props.multiple && !props.renderValue) {
			this.renderValue = this.multipleRenderValue;
			this.placeholder = this.placeholder || t('boardList.all');
		} else {
			this.renderValue = props.renderValue;
		}
	}

	multipleRenderValue = ({ item }) => {
		const value = this.props.value;
		const index = value.findIndex(({ id }) => id === item.id);

		if (index === 0) {
			if (value.length === 1) {
				return renderStatus(item, { size: this.props.size, truncate: true });
			}
			return `${this.props.selectedPlaceholder || t('selected')}: ${value.length}`;
		}

		return null;
	};

	render() {
		const { renderValue, placeholder } = this;
		const { selectedPlaceholder, ...restComboboxProps } = this.props;
		const className = classnames('ToolbarListSelect', this.props.className);
		return <StatusSelect {...restComboboxProps} className={className} renderValue={renderValue} placeholder={placeholder} />;
	}
}

