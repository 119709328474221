import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { FormContext } from '@smartplatform/ui';
import { Attach } from '@features/Attach';
import store from 'client/store';
import t from 'i18n';
import { AutoAnimate } from '@smartplatform/consta/ui/AutoAnimate';
import './attachments.scss';
import PaperclipIcon from '@phosphor-icons/core/regular/paperclip.svg';
import DownloadIcon from '@phosphor-icons/core/regular/download-simple.svg';
import { Button } from '@consta/uikit/Button';

// prohibit

@observer
export class Attachments extends React.Component {
	static propTypes = {
		record: PropTypes.object.isRequired,
		relation: PropTypes.string,
		property: PropTypes.string,
		canUpload: PropTypes.bool,
		canDelete: PropTypes.bool,
		uploadIsImage: PropTypes.bool,
		onChange: PropTypes.func,
		onDelete: PropTypes.func,
		softDelete: PropTypes.bool,
		withFormContext: PropTypes.bool,

		archiveAttachments: PropTypes.bool,
	};

	static contextType = FormContext;

	static defaultProps = {
		relation: 'attachments',
		property: 'filename',
		canUpload: true,
		canDelete: true,
		softDelete: false,
		withFormContext: false,

		archiveAttachments: false,
	};

	@observable attachments = [];
	@observable newFiles = [];
	@observable isUploading = false;
	@observable preparationArchive = false;
	@observable percentProgressCompression = 0;

	constructor(props) {
		super(props);
		this.record = this.props.record;
		this.id = this.props.record.MODEL.name + '-' + this.props.record.id;
		const modelName = this.props.record.MODEL.RELATIONS[this.props.relation].model;
		this.model = store.model[modelName];
		this.init();
	}

	componentDidUpdate = (prevProps) => {
		if (this.props.attachments && this.props.attachments.length !== prevProps.attachments.length) {
			this.init();
		}
	};

	init = async () => {
		if (this.props.attachments) {
			this.attachments = this.props.attachments;
		} else if (this.record.id) {
			this.attachments = await this.record[this.props.relation].find({ include: ['owner'], order: 'id asc' });
		}
	};

	onAttach = async (e) => {
		const newFiles = [];

		for (let file of e.target.files) {
			const attachment = new this.model();
			attachment[this.props.property] = file.name;
			newFiles.push({
				attachment,
				file,
				uploadProgress: 0,
				done: false,
				error: null,
			});
		}
		this.newFiles = newFiles;
		this.isUploading = true;
		const promises = this.newFiles.map(this.upload);
		await Promise.all(promises);
		this.isUploading = false;
		const successFiles = this.newFiles.filter((uploadObj) => !uploadObj.error);
		if (successFiles.length && this.props.onChange) {
			await this.props.onChange(successFiles);
		}

		// оставим новые файлы с ошибками, обнуляем при следующем аплоаде
		this.newFiles = this.newFiles.filter((uploadObj) => uploadObj.error);
	};

	handleFileProgress = (uploadObj, event) => {
		uploadObj.uploadProgress = event.percent;
	};

	upload = async (uploadObj) => {
		await uploadObj.attachment.save();
		await uploadObj.attachment.owner;
		try {
			const res = await this.uploadFile(uploadObj);
			if (this.props.withFormContext) {
				this.context.form.addManyToMany(this.props.relation, uploadObj.attachment.id);
			} else {
				await this.props.record[this.props.relation].add(uploadObj.attachment.id);
			}

			uploadObj.done = true;
			this.attachments.push(uploadObj.attachment);
			this.props.archiveAttachments && (await this.record.reload());
		} catch (error) {
			if (error.status === 413) {
				uploadObj.error = t('file.fileTooBig');
			} else {
				uploadObj.error = t('file.uploadError');
			}
			await uploadObj.attachment.delete();
		}
	};

	uploadFile = (uploadObj) =>
		new Promise((resolve, reject) => {
			uploadObj.attachment
				.uploadFile(this.props.property, uploadObj.file)
				.on('progress', (event) => this.handleFileProgress(uploadObj, event))
				.end((error, result) => {
					if (!error) {
						resolve(result);
					} else {
						reject(error);
					}
				});
		});

	deleteAttach = async ({ attachment, property }) => {
		const index = this.attachments.findIndex((_attachment) => _attachment.id === attachment.id);
		if (index !== -1) this.attachments.splice(index, 1);
		if (this.props.withFormContext) this.context.form.removeManyToMany(this.props.relation, attachment.id);
		else {
			await this.props.record[this.props.relation].remove(attachment.id);
			if (!this.props.softDelete) {
				await attachment.deleteFile(property);
				await attachment.delete();
			}
		}
		if (this.props.onDelete) this.props.onDelete();

		this.props.archiveAttachments && (await this.record.reload());
	};

	handlerProgressCompression = (data) => {
		this.percentProgressCompression = parseInt((data.processedBytes / data.totalBytes) * 100);
	};

	downloadArchiveAttachments = async () => {
		if (!this.record.archiveAttachments) {
			store.socket.on('Record.progressCompression', this.handlerProgressCompression);
			this.preparationArchive = true;
			const resultCreate = await this.record.createArchiveAttachments();
			this.preparationArchive = false;
			if (resultCreate) {
				store.socket.removeAllListeners('Record.progressCompression');
				this.percentProgressCompression = 0;
				location.href = this.record.downloadFile('archiveAttachments');
			}
		} else {
			location.href = this.record.downloadFile('archiveAttachments');
		}
		await this.record.reload();
	};

	render() {
		const { RenderCustomAttachment, renderCustomAttachmentButton, canDelete } = this.props;

		const button = renderCustomAttachmentButton || <Button iconLeft={PaperclipIcon} label={t('toAttachFile')} view='clear' />;

		return (
			<>
				<div
					className={classNames('attachments', {
						'upload-image': this.props.uploadIsImage,
						loading: this.isUploading,
					})}
				>
					{this.props.canUpload && (
						<div className='upload'>
							<input type='file' id={this.id} onChange={this.onAttach} multiple disabled={this.isUploading} />
							<label htmlFor={this.id}>{button}</label>
						</div>
					)}
					{this.props.archiveAttachments && this.attachments.length > 0 && this.props.record.id && (
						<Button
							iconLeft={DownloadIcon}
							onClick={this.downloadArchiveAttachments}
							loading={this.preparationArchive}
							label={
								this.preparationArchive ? (
									<div className='process-archive-attachments'>
										<span>{t('archiveAttachments.preparation')}</span>
										<span>{this.percentProgressCompression}%</span>
									</div>
								) : (
									t('archiveAttachments.download')
								)
							}
						/>
					)}
				</div>
				<div className='errors-and-list'>
					<div className='new-files'>
						{this.newFiles.reverse().map((uploadObj, i) => {
							const loadingProgresss =
								typeof uploadObj.uploadProgress === 'number' ? Math.floor(uploadObj.uploadProgress) : undefined;
							return (
								<Attach
									key={i}
									attachment={uploadObj.attachment}
									size='xs'
									errorText={uploadObj.error}
									loadingProgresss={loadingProgresss}
									loading={!uploadObj.error}
								/>
							);
						})}
					</div>
					{this.attachments.length > 0 && (
						<AutoAnimate className='list'>
							{this.attachments
								.reverse()
								.map((attachment) =>
									RenderCustomAttachment ? (
										<RenderCustomAttachment
											key={attachment.id}
											attachment={attachment}
											deleteAttach={this.deleteAttach}
										/>
									) : (
										<Attach
											key={attachment.id}
											canDelete={this.props.canDelete}
											attachment={attachment}
											onButtonClick={canDelete ? this.deleteAttach : undefined}
											size='xs'
										/>
									)
								)}
						</AutoAnimate>
					)}
				</div>
			</>
		);
	}
}
