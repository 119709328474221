import { UserAvatar } from '@smartplatform/consta/ui/UserAvatar';
import React from 'react';
import store from 'client/store.js';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import t from 'i18n';
import BillingIcon from '@phosphor-icons/core/regular/coins.svg';
import ProfileIcon from '@phosphor-icons/core/regular/user-gear.svg';
import SupportIcon from '@phosphor-icons/core/regular/question.svg';
import SignOutIcon from '@phosphor-icons/core/regular/sign-out.svg';
import HelpIcon from '@phosphor-icons/core/regular/book-open-text.svg';
import { Sidebar } from '@consta/uikit/Sidebar';
import { Navbar } from '@consta/header/Navbar';
import { Billing } from '@widgets/Billing';

const HELP_END_PATH = {
	ru: '/ru-help/',
	en: '/en/help/',
};

@observer
export class MobileAccountMenu extends React.Component {
	@observable isAccountMenuOpen = false;
	@observable isBillingOpen = false;
	helpUrl = 'https://smart-erp.pro';

	goTo = (path) => () => store.route.push({ path });

	openAccountMenu = () => (this.isAccountMenuOpen = true);

	closeAccountMenu = () => (this.isAccountMenuOpen = false);

	openHelp = () => {
		const { code } = store.model.user?.language || {};
		window.open(this.helpUrl + HELP_END_PATH[code || 'ru']);
	};

	logout = () => store.model.logout();

	toggleFeedbackPopup = () => {
		store.ui.showFeedbackPopup = !store.ui.showFeedbackPopup;
	};

	openBilling = () => store.ui.showPopupBilling = true;
	
	accountMenuItems = [
		{
			label: t('user.profile'),
			onClick: this.goTo('/profile'),
			icon: () => <ProfileIcon />,
		},
		{
			label: t('help'),
			onClick: this.openHelp,
			icon: () => <HelpIcon size='m' />,
		},
		{
			label: t('support'),
			onClick: this.toggleFeedbackPopup,
			icon: () => <SupportIcon />,
		},
		{
			label: t('billing.title'),
			onClick: this.openBilling,
			icon: () => <BillingIcon size='m' />,
		},
		{
			label: t('logout'),
			onClick: this.logout,
			icon: () => <SignOutIcon size='m' />,
		},
	].filter(Boolean);

	onItemClick = (item) => {
		item.onClick && item.onClick();
		this.isAccountMenuOpen = false;
	};

	render() {
		const { openAccountMenu, closeAccountMenu, accountMenuItems, isAccountMenuOpen } = this;
		return (
			<>
				<UserAvatar user={store.model.user} onClick={openAccountMenu} fioShort />
				<Sidebar position='bottom' isOpen={isAccountMenuOpen} onClickOutside={closeAccountMenu} size='s'>
					<Navbar items={accountMenuItems} onItemClick={this.onItemClick} />
				</Sidebar>
				<Billing />
				</>
		);
	}
}
