import { Responses500 } from '@consta/uikit/Responses500';
import { Text } from '@consta/uikit/Text';
import { ToRootButton } from '@features/ToRootButton';
import store from '@appStore';
import './ErrorFallback.scss';
import { ConstaTheme } from '../../ConstaTheme';

export const ErrorFallback = ({ error, resetErrorBoundary }) => {
	console.error(error);
	console.log(store, '333');
	return (
		<ConstaTheme>
			<Responses500
				className='ErrorFallback'
				actions={
					<div className='actions'>
						<Text truncate view='alert'>
							{error.message}
						</Text>
						<ToRootButton onClick={resetErrorBoundary} />
					</div>
				}
			/>
		</ConstaTheme>
	);
};
