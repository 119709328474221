import { observable } from 'mobx';
import { v4 as uuid } from 'uuid';
import { setHours, setMinutes } from 'date-fns';

export default class UI {
	@observable title = '';
	@observable backRoute = null;
	@observable menu = false;
	@observable activeSubMenu = null;
	@observable kanban = false;
	@observable notifications = [];
	@observable showFeedbackPopup = false;
	@observable showPopupBilling = false;

	// возможно будут в настройках?
	workStartTime = setHours(setMinutes(new Date(), 0), 8);
	workEndTime = setHours(setMinutes(new Date(), 0), 20);

	constructor(store) {
		this.store = store;
	}

	setNotification = (notification) => {
		if (notification) {
			this.notifications.unshift({ ...notification, id: uuid() });
		}
	};

	removeNotification = (id) => {
		const index = this.notifications.findIndex((n) => n.id === id);
		index !== -1 && this.notifications.splice(index, 1);
	};
}
