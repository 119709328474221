import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { AvatarGroup } from '../avatar-group';
import { Text } from '@consta/uikit/Text';

@observer
export class ToolbarAvatarGroup extends Component {
	static propTypes = {
		items: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
		members: PropTypes.array,
		size: PropTypes.number,
		gap: PropTypes.number,
		badgeKey: PropTypes.string,
		avatarsCount: PropTypes.number,
	};
	static defaultProps = {
		size: 30,
		avatarsCount: 20,
		gap: 5,
		badgeKey: null,
		renderBadge: undefined,
	};

	render() {
		const { members, renderBadge, items, badgeKey, onItemClick, selectedId, size, avatarsCount, title } = this.props;
		return (
			<div className='members'>
				<Text className='title'>{title}:</Text>
				<AvatarGroup
					size={size}
					items={items || members}
					badgeKey={badgeKey}
					renderBadge={renderBadge}
					onItemClick={onItemClick}
					selectedId={selectedId}
					avatarsCount={avatarsCount}
				/>
			</div>
		);
	}
}

