import { User } from './User';
import t from 'i18n';
import LockIcon from '@phosphor-icons/core/regular/lock.svg';
import { Text } from '@consta/uikit/Text';

const STYLE = { cursor: 'pointer' };

const LockIconComponent = ({ size }) => console.log(size) || <LockIcon view='alert' size='m' />;
// функция рендера для компонента Combobox

export const renderUser = (user, options = {}) => {
	if (!user) return;
	const props = {};
	let style;
	if (user.item) {
		// renderValue
		const { item, handleRemove } = user;
		props.user = item;
		style = STYLE;
		//! тут надо будет дописать логику крестик
		// props.onCancel = handleRemove;
	} else {
		// getItemLabel
		props.user = user;
	}
	let info = options.info;
	if (!info) {
		info = user.isBlocked ? <Text view='alert'>{t('user.isBlocked')}</Text> : undefined;
	}

	// label - условный лейбл для тех записей, которые не имеют аватара
	if (!props.user.id) return props.user.label;

	const size = options.size || 'm';
	const showBlockIcon = options.showBlockIcon !== 'false';
	const iconRight = showBlockIcon && user.isBlocked ? LockIconComponent : undefined;
	const width = options.width || 'default';

	return (
		<User
			key={props.user.id}
			meLabel={options.meLabel}
			user={props.user}
			size={size}
			className='renderUser'
			info={info}
			style={style}
			iconRight={iconRight}
			width={width}
		/>
	);
};

