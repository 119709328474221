import React from 'react';
import { observer } from 'mobx-react';
import store from 'client/store';
import { ModuleMenu } from './ModuleMenu.js';

@observer
export class SideBarMenu extends React.Component {
	render() {
		const ModuleMenuItems = [];
		for (const moduleItem of store.availableModules) {
			ModuleMenuItems.push(<ModuleMenu key={moduleItem.code} module={moduleItem} />);
		}
		return <div className='SideBarMenu'>{ModuleMenuItems}</div>;
	}
}
