import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Text } from '@consta/uikit/Text';
import t from 'i18n';

@observer
export class ToolbarCount extends React.Component {
	static propTypes = {
		text: PropTypes.string.isRequired,
		count: PropTypes.number,
	};

	render() {
		const { text = t('total'), count } = this.props;

		return (
			<Text className='toolbar-count'>
				{text}: {count}
			</Text>
		);
	}
}

