import React from 'react';
import { Feedback } from './Feedback';
import { Modal } from '@consta/uikit/Modal';
import { Button } from '@consta/uikit/Button';
import X from '@phosphor-icons/core/regular/x.svg';

import store from 'client/store';
import './feedback-popup.scss';

export class FeedbackPopup extends React.Component {
	closePopup = () => (store.ui.showFeedbackPopup = false);

	render() {
		return (
			<Modal
				isOpen={this.props.isOpen}
        hasOverlay
				className='wrapper-feedback-form'
        onClickOutside={this.closePopup}
        onEsc={this.closePopup}
      >
        <div className='content'>
					<div className='wrapper-close-popup'>
						<X onClick={this.closePopup} />
					</div>
					<Feedback />
				</div>
      </Modal>
		);
	}
}