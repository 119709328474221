import './KanbanListHeader.scss';
import { Text } from '@consta/uikit/Text';
import { ContextMenuButton } from '@features/ContextMenuButton';
import { Button } from '@consta/uikit/Button';
import React from 'react';
import ExportIcon from '@phosphor-icons/core/regular/file-arrow-up.svg';
import IconAdd from '@phosphor-icons/core/regular/plus.svg';
import hideIcon from '@phosphor-icons/core/regular/caret-left.svg';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { addResourceBundle } from './i18n';

addResourceBundle();

@withTranslation('components', { keyPrefix: 'KanbanListHeader' })
@observer
export class KanbanListHeader extends React.Component {
	menuItems = [];
	constructor(props) {
		super(props);
		const { list, onAdd, onExport, onHide, menuItems, t } = props;
		this.menuItems = menuItems;

		if (!this.menuItems) {
			this.menuItems = [
				onExport && { label: t('export'), onClick: () => onExport(list), iconLeft: ExportIcon },
				onHide && { label: t('hide'), onClick: () => onHide(list), iconLeft: hideIcon },
			].filter(Boolean);
		}
	}

	onAdd = () => this.props?.onAdd(this.props.list);
	render() {
		const { list, onAdd } = this.props;

		return (
			<div className='KanbanListHeader' style={{ borderColor: list.color }}>
				<Text truncate weight='bold' transform='uppercase'>
					{list.name} {list.totalCount > 0 ? `(${list.totalCount})` : null}
				</Text>
				<div className='actions'>
					{onAdd && <Button onClick={this.onAdd} onlyIcon iconLeft={IconAdd} form='round' view='clear' size='s' />}
					<ContextMenuButton size='s' items={this.menuItems} />
				</div>
			</div>
		);
	}
}
