import store from 'client/store.js';
import React from 'react';
import t from 'i18n';
import { observer } from 'mobx-react';
import { AvatarGroup } from '@smartplatform/consta/ui/AvatarGroup';
import { Text } from '@consta/uikit/Text';
import './OnlineUsers.scss';

@observer
export class OnlineUsers extends React.Component {
	render() {
		const onlineUsers = [...store.onlineUsersMap.values()].reverse();
		if (onlineUsers.length === 0) return null;

		return (
			<div className='OnlineUsers'>
				<Text className='online'>{t('online')}</Text>
				<AvatarGroup items={onlineUsers} size='s' visibleCount={20} />
			</div>
		);
	}
}
