import React from 'react';
import Root from './Root';
import {
	CATALOG_PATH,
	ORDERS_PATH,
	ORDERS_REPORT_PATH,
	PRICE_CATEGORIES_PATH,
	SETTINGS_PATH,
	SALES_REPORTS_PATH,
	SALES_DASHBOARD_PATH,
} from './constants';
import t from 'i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OrderIcon from 'client/img/sidebar/order.svg';
import { BadgeTitle } from 'components';
import salesStore from './salesStore';

import DashboardIcon from '@phosphor-icons/core/regular/chart-pie-slice.svg';
import OrdersIcon from '@phosphor-icons/core/regular/kanban.svg';
import CatalogIcon from '@phosphor-icons/core/regular/tag.svg';
import ReportIcon from '@phosphor-icons/core/regular/cards-three.svg';
import SettingsIcon from '@phosphor-icons/core/regular/sliders-horizontal.svg';

/**
 * Конфигурация модуля.
 */

export default {
	/** Заголовок модуля */
	title: t('sales.title'),

	/** Короткий заголовок модуля */
	titleShort: t('sales.title'),

	/** Код модуля на латинице, обязателен */
	code: 'sales',

	/** Опциональная дополнительная функция проверки доступности модуля */
	enabled: (store) => store.model.Order && store.model.Order.INFO.READ,

	/** Главный компонент с роутами и компонентами модуля */
	component: Root,

	/** Необязательный компонент для общего дашбоарда, см. исходник */
	// dashboardCard: SalesCard,

	/** Меню модуля, может быть массивом или функцией (в аргументе передастся глобальный стор) */
	menu: () => {
		return [
			{
				type: 'item',
				label: t('analytics.title'),
				path: SALES_DASHBOARD_PATH,
				icon: () => <DashboardIcon size='m' />,
			},
			{
				type: 'item',
				label: t('order.plural'),
				path: ORDERS_PATH,
				icon: () => <OrdersIcon size='m' />,
				enabled: (store) => store.model.Order && store.model.Order.INFO.READ,
			},
			/*{
				type: 'item',
				label: t('order.report'),
				path: ORDERS_REPORT_PATH,
				icon: faCashRegister,
				enabled: (store) => store.model.Order && store.model.Order.INFO.READ,
			},*/
			{
				type: 'deprecated',
				label: t('priceList.title'),
				path: PRICE_CATEGORIES_PATH,
				icon: () => <CatalogIcon size='m' />,
				enabled: (store) => salesStore.showOldPriceList && store.model.PriceCategory && store.model.PriceCategory.INFO.WRITE,
			},
			{
				type: 'item',
				label: t('catalog.title'),
				path: CATALOG_PATH,
				icon: () => <CatalogIcon size='m' />,
				enabled: (store) => store.model.PriceList && store.model.ProductCategory.INFO.READ,
			},
			{
				type: 'item',
				label: t('reports.plural'),
				path: SALES_REPORTS_PATH,
				icon: () => <ReportIcon size='m' />,
			},
			{
				type: 'item',
				label: t('settings'),
				path: SETTINGS_PATH,
				icon: () => <SettingsIcon size='m' />,
				enabled: (store) => store.model.Feature && store.model.Feature.INFO.WRITE,
			},
		];
	},
};
