import store from '@appStore';
import { observer } from 'mobx-react';
import logoMiniLight from 'client/img/logo-mini-light.svg?url';
import logoMiniDark from 'client/img/logo-mini-dark.svg?url';
import logoLight from 'client/img/logo-light.svg?url';
import logoDark from 'client/img/logo-dark.svg?url';
import { classnames } from '@bem-react/classnames';
import { Picture } from '@consta/uikit/Picture';

import './LogoLabel.scss';

export const LogoLabel = observer(({ className, onClick, withLabel = false }) => {
	const isDefaultTheme = store.local.theme === 'DEFAULT';
	let logo = null;
	if (withLabel) {
		if (isDefaultTheme) {
			logo = logoMiniLight;
		} else {
			logo = logoMiniDark;
		}
	} else {
		if (isDefaultTheme) {
			logo = logoLight;
		} else {
			logo = logoDark;
		}
	}

	const styles = {
		cursor: onClick ? 'pointer' : 'default',
	};

	if (!logo) return null;

	return (
		<div className={classnames('LogoLabel', className)} onClick={onClick} style={styles}>
			<Picture src={'/api' + logo} className={!withLabel ? 'LogoText' : undefined} />
		</div>
	);
});
