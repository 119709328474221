import React from 'react';
import { observer } from 'mobx-react';
import store from 'client/store';
import classNames from 'classnames';
import { LogoLabel } from '@features/LogoLabel';
import { ThemeToggler } from '@features/ThemeToggler';
import t from 'i18n';
import { Text } from '@consta/uikit/Text';
import { SideBarMenu } from './SideBarMenu';
import { SidebarSwitcher } from './SidebarSwitcher';
import './SideBar.scss';

@observer
export class SideBar extends React.Component {
	constructor(props) {
		super(props);
		this.init();
	}

	init = () => {
		store.ui.menu = store.local.menuState;
	};

	onToggleSidebarMode = () => {
		store.ui.menu = !store.ui.menu;
		store.local.menuState = store.ui.menu;
	};

	goToRoot = () => store.router.navigate('/');

	render() {
		const sidebarClassName = classNames('SideBar', !store.ui.menu ? 'short' : undefined);

		return (
			<div className={sidebarClassName}>
				<div className='SideBar-header'>
					<SidebarSwitcher className='SideBar-size-switcher' fullMode={store.ui.menu} onClick={this.onToggleSidebarMode} />
					<LogoLabel className='SideBar-logo' withLabel={!store.ui.menu} onClick={this.goToRoot} />
				</div>
				<SideBarMenu />
				<div className='SideBar-footer-theme-toggle-wrapper'>
					{store.ui.menu && <Text>{t('selectTheme')}</Text>}
					<ThemeToggler />
				</div>
				<div className='SideBar-footer-copyright'>
					<Text size='2xs' lineHeight='xs'>
						{VERSION} ({BUILD})
					</Text>
					{store.ui.menu && (
						<Text size='2xs' lineHeight='xs'>
							© SmartUnit LLC {new Date().getFullYear()}
						</Text>
					)}
				</div>
			</div>
		);
	}
}
