import React from 'react';
import Root from './Root';
import t from 'i18n';
import { ADMIN_ROLES_PATH, ADMIN_SETTINGS_PATH, ADMIN_USERS_PATH } from './constants';
import UsersIcon from '@phosphor-icons/core/regular/users-three.svg';
import RolesIcon from '@phosphor-icons/core/regular/user-gear.svg';
import SettingsIcon from '@phosphor-icons/core/regular/sliders-horizontal.svg';

export default {
	/** Заголовок модуля */
	title: t('administration'),

	/** Короткий заголовок модуля */
	titleShort: t('admin'),

	/** Код модуля на латинице, обязателен */
	code: 'admin',

	/** Опциональная дополнительная функция проверки доступности модуля */
	enabled: (store) => store.model.ACL?.INFO.WRITE,

	/** Главный компонент с роутами и компонентами модуля */
	component: Root,

	/** Необязательный компонент для общего дашбоарда */
	// dashboardCard: DocumentsCard,

	/** Меню модуля, может быть массивом или функцией (в аргументе передастся глобальный стор) */
	menu: () => [
		{
			type: 'item',
			label: t('users'),
			path: ADMIN_USERS_PATH,
			icon: () => <UsersIcon size='m' />,
		},
		{
			type: 'item',
			label: t('roles'),
			path: ADMIN_ROLES_PATH,
			icon: () => <RolesIcon size='m' />,
		},
		// {
		// 	type: 'item',
		// 	path: ADMIN_ACLS_PATH,
		// 	label: t('acls'),
		// 	icon: faIdCard,
		// },
		{
			type: 'item',
			label: t('settings'),
			path: ADMIN_SETTINGS_PATH,
			icon: () => <SettingsIcon size='m' />,
			enabled: (store) => store.model.Config?.INFO.WRITE,
		},
	],
};
