import { observable } from 'mobx';
import { createRef } from 'react';
import { MODULES, YEAR, QUARTER, MONTH } from './constants';

import store from 'client/store';
import { t } from 'i18next';

export class BillingStore {
	@observable info = {
		cost: '/'
	};
	@observable error = false;
	@observable isBilling = false;
	@observable modules = MODULES;
	@observable changeTariffPayment = null;
	@observable isPaidTariff = false;
	@observable isTariffChange = false;
	@observable extendError = null;
	@observable period = MONTH;
	@observable total = {};
	@observable discSpace = { start: 1, update: 0 };
	@observable isChangeSpace = false;
	@observable isLoading = false;
	@observable confirmStatus = null;

	refCloseBilling = createRef();

	constructor() {
		this.init();
	}

	init = async () => {
		this.isLoading = true;

		try {
			this.isBilling = (await store.model.User.isBilling()) || false;
		} catch (e) {
			this.isBilling = false;
		}

		if (this.isBilling) {
			const billingInfo = await store.model.User.fetchingBilling();
			if (billingInfo.error && billingInfo.error === 'authError') {
				this.error = 'authError';
				this.isLoading = false;
				return null;
			}

			this.info = billingInfo;

			if (this.info.autoprolong === '3') this.period = QUARTER;
			else if (this.info.autoprolong === '12') this.period = YEAR;
			else this.period = MONTH;

			const configTariffPayment = (await store.model.Config.find({ where: { code: 'changeTariffPayment' } }))?.[0];
			if (configTariffPayment) {
				this.changeTariffPayment = JSON.parse(configTariffPayment.value);
				this.isPaidTariff = await store.model.User.isPaidTariff({ id: this.changeTariffPayment.paymentId });
				if (!this.isPaidTariff) {
					const time = setInterval(async () => {
						this.isPaidTariff = await store.model.User.isPaidTariff({ id: this.changeTariffPayment.paymentId });
						if (this.isPaidTariff) {
							clearInterval(time);
							await configTariffPayment.delete();
							store.ui.showPopupBilling = false;
							this.isPaidTariff = true;
							this.isChangeSpace = false;
							this.init();
						}
					}, 10000);
				}
			}

			this.discSpace = { start: +this.info.paidSpace, update: +this.info.paidSpace };

			this.modules = this.modules.map((module) => {
				const costText = this.info.modules && this.info.modules[module.name][1];
				let cost = 0;

				if (costText) {
					cost = parseFloat(costText.replace(/\s+/g, '').match(/\d+/));
					return { ...module, checked: this.info.modules[module.name][0] || false, cost };
				}

				return module;
			});

			this.total = {
				year: this.info.totalArr && parseFloat(this.info.totalArr[4]['$']),
				quarter: this.info.totalArr && parseFloat(this.info.totalArr[2]['$']),
				month: this.info.totalArr && parseFloat(this.info.totalArr[1]['$']),
			};
		}

		this.isLoading = false;
	};

	onTogglePopup = () => {
		if (!this.isLoading && !this.error) {
			store.ui.showPopupBilling = !store.ui.showPopupBilling;
		}
	};

	extension = async () => {
		this.showPopup = false;
		const res = await store.model.User.extendTariff({ elid: this.info.elid, period: this.period.value });

		if (res.status === 'error' && res.type === 'not_enough_money') {
			const { url } = await store.model.User.orderExtension({ elid: this.info.elid, period: this.period.value });

			const link = document.createElement('a');
			link.href = url;
			link.target = '_blank';
			link.click();
			link.remove();
		} else if (res.status === 'error') {
			this.extendError = { mes: t('billing.notEnoughMoney') };
		} else {
			this.confirmStatus = 'successPay'
		}

		this.confirmStatus = null;
		this.init();
	};

	isChangesModules = () => {
		return this.modules.some((mod) => {
			if (!(mod.checked === this.info.modules[mod.name][0])) {
				return true;
			}
		});
	};

	onChangeModule = (module) => async () => {
		const target = this.modules.find((mod) => mod.name === module);
		if (target.name === 'management' && target.checked) return null;

		target.checked = !target.checked;

		if (!this.info.isFree) {
			await this.getTotalEditTariff('edit');
		} else if (this.isChangeSpace && this.discSpace.update > 1 && this.info.isFree) {
			await this.getTotalEditTariff('transition');
		}

		this.isTariffChange = this.isChangesModules();
	};

	editTariff = async () => {
		let result = null;
		this.confirmStatus = null;

		try {
			result = await store.model.User.editBilling({
				elid: this.info.elid,
				modules: this.modules,
				space: this.discSpace.update,
				isFree: this.info.tariffId === '19',
			});

			if (result.doc?.error?.['$type'] === 'not_enough_money') {
				const { url } = await store.model.User.orderExtension({
					elid: this.info.elid,
					modules: this.modules,
					space: this.discSpace.update.toString(),
					isFree: this.info.tariffId === '19',
				});

				const link = document.createElement('a');
				link.href = url;
				link.target = '_blank';
				link.click();
				link.remove();
			} else {
				this.confirmStatus = 'successPay'
			}
		} catch (e) {
			console.error('Ошибка при добавлении модулей: ', e);
			this.confirmStatus = 'failedPay'
		}

		this.init();
		this.isChangeSpace = false;
		this.isTariffChange = false;
	};

	onChangePeriod = (value) => {
		this.period = value;
		this.getTotalEditTariff('transition');
	};

	onChangeSpace = async (value) => {
		const _value = Number(value);
		let discSpace;
		if (_value >= 1 && _value <= 100) {
			discSpace = { ...this.discSpace, update: _value };
		} else if (_value < 1) {
			discSpace = { ...this.discSpace, update: 1 };
		} else if (_value > 100) {
			discSpace = { ...this.discSpace, update: 100 };
		}
		
		this.discSpace = discSpace;
		const isChangeSpace = discSpace.start !== discSpace.update;
		if (isChangeSpace && !this.info.isFree) {
			await this.getTotalEditTariff('edit');
		} else if (isChangeSpace && _value > 1 && this.info.isFree) {
			await this.getTotalEditTariff('transition');
		} else if (!isChangeSpace) {
			await this.getTotalEditTariff('reset');
		}
		this.isChangeSpace = isChangeSpace;
	};

	getTotalEditTariff = async (action) => {
		if (action === 'edit') {
			this.total = {
				...this.total,
				editTariff: await store.model.User.getTotalOfAdding({
					elid: this.info.elid,
					modules: this.modules,
					space: this.discSpace.update,
					tariffId: this.info.tariffId,
				}),
			};
		} else if (action === 'transition') {
			this.total = {
				...this.total,
				editTariff: await store.model.User.getTotalOfAdding({
					modules: this.modules,
					space: this.discSpace.update,
					tariffId: this.info.tariffId,
					period: this.period.value,
				}),
			};
		} else if (action === 'reset') {
			this.total = {
				...this.total,
				editTariff: '0',
			};
		}
	};

	getChangeTariffAndPay = async () => {
		this.isPaidTariff = false;
		this.changeTariffPayment = true;
		const { url, paymentId } = await store.model.User.getChangeTariffAndPay({
			elid: this.info.elid,
			modules: this.modules,
			space: this.discSpace.update,
			isFree: this.info.isFree,
			period: this.period.value,
		});

		await store.model.Config.batchUpdate([
			{
				code: 'changeTariffPayment',
				value: JSON.stringify({ paymentId, url }),
			},
		]);

		const link = document.createElement('a');
		link.href = url;
		link.target = '_blank';
		link.click();
		link.remove();
		this.init();
	};

	onChangeTariff = () => {
		this.discSpace.update = this.isChangeSpace ? 1 : 2;
		this.getTotalEditTariff(this.isChangeSpace ? 'reset' : 'transition');
		this.isChangeSpace = !this.isChangeSpace;
	}

	cancelPayment = async () => {
		const isPaid = await store.model.User.isPaidTariff({ id: this.changeTariffPayment.paymentId });
		if (isPaid) {
			this.init();
			return;
		}
		await store.model.User.cancelPayment({ elid: this.info.elid });
		const changeTariffPayment = (await store.model.Config.find({ where: { code: 'changeTariffPayment' } }))[0];
		await changeTariffPayment.delete();
		this.isPaidTariff = true;
		this.isChangeSpace = false;
		this.total = {
			...this.total,
			editTariff: '0',
		};

		this.init();
	};

	closePopup = () => (store.ui.showPopupBilling = false);
	
	closePopupOutside = (event) => {
		// для обработки повторного нажатия на открытие
		if (!this.refCloseBilling.current?.contains(event.target)) this.closePopup();
	}

	openConfirm = (prop) => () => {
		this.confirmStatus = prop;
	}

	closeConfirm = () => {
		this.confirmStatus = null;
	}
}