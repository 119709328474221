import React, { Component } from 'react';
import { observer } from 'mobx-react';
import background from 'client/img/auth_background.png';
import { FeedbackPopup } from '@widgets/FeedbackPopup';
import { FeedbackButton } from '@features/FeedbackButton';

import store from '@appStore';
import './authLayout.scss';

@observer
export class AuthLayout extends Component {
	render() {
		return (
			<div className='auth-layout'>
				<div className='auth-background'>
					<img src={background} alt='background' />
					<div className='background-layout'></div>
				</div>
				{React.cloneElement(this.props.children, this.props)}
				<FeedbackButton />
				<FeedbackPopup isOpen={store.ui.showFeedbackPopup} />
			</div>
		);
	}
}

