import React from 'react';
import { ThemeToggler as _ThemeToggler } from '@consta/uikit/ThemeToggler';
import { IconSun } from '@consta/icons/IconSun';
import { IconMoon } from '@consta/icons/IconMoon';
import { IconLightningBolt } from '@consta/icons/IconLightningBolt';
import store from '@appStore';
import { observer } from 'mobx-react';
import { addResourceBundle } from './i18n';
import { withTranslation } from 'react-i18next';

addResourceBundle();

@withTranslation('components', { keyPrefix: 'ThemeToggler' })
@observer
export class ThemeToggler extends React.Component {
	constructor(props) {
		super(props);
		const { t } = props;
		this.themes = [
			{
				code: 'DEFAULT',
				label: t('default'),
				icon: IconSun,
			},
			{
				code: 'DARK',
				icon: IconMoon,
				label: t('dark'),
			},
			{
				code: 'DISPLAY',
				label: t('display'),
				icon: IconLightningBolt,
			},
		];
	}
	onChange = ({ code }) => {
		store.local.theme = code;
	};

	render() {
		const value = this.themes.find((item) => item.code === store.local.theme) || this.themes[0];
		return (
			<_ThemeToggler
				getItemKey={(item) => item.code}
				items={this.themes}
				value={value}
				onChange={this.onChange}
				direction='downStartLeft'
			/>
		);
	}
}
