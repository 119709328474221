import React from 'react';
import { Layout } from '@consta/uikit/Layout';
import { MobileMenu } from '@consta/header/MobileMenu';
import store from 'client/store';
import { MobileAccountMenu } from './MobileAccountMenu';
import './MobileHeader.scss';

export class MobileHeader extends React.Component {
	constructor(props) {
		super(props);
		this.init();
	}

	init = () => {
		this.items = store.availableModules.map((module) => {
			const moduleItem = {
				label: module.title,
				onClick: (e) => {
					e.preventDefault();
					e.stopPropagation();
				},
			};
			if (module.menu) {
				moduleItem.subMenu = typeof module.menu === 'function' ? module.menu() : module.menu;
			}
			return moduleItem;
		});
	};

	onItemClick = (item) => {
		if (item.path) {
			store.route.push({ path: item.path });
			store.local.lastPath = item.path;
		}
	};

	render() {
		return (
			<Layout className='MobileHeader' direction='row'>
				<MobileMenu items={this.items} onItemClick={this.onItemClick} />
				<MobileAccountMenu />
			</Layout>
		);
	}
}
