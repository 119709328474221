import React from 'react';
import { inject, observer } from 'mobx-react';
import { Text } from '@consta/uikit/Text';
import { TextField } from '@consta/uikit/TextField';
import { Slider } from '@consta/uikit/Slider';
import { CurrencyValue } from 'components';
import { debounce } from 'lodash';

import { t } from 'i18next';
import './BillingSpaces.scss';

@inject('context')
@observer
export class BillingSpaces extends React.Component {
	doChangeSpace = debounce(this.props.context.onChangeSpace, 500, { leading: false, trailing: true });

	render() {
		const { props, doChangeSpace } = this;
		const { onChangeSpace, discSpace, info } = props.context;
		const { spacePrice, isPaymentWaitingMode } = props;

		return (
			<div className='BillingSpaces'>
				<TextField
					type='number'
					label={
						<div className='billing-spaces-line'>
							<Text weight='light' view='secondary' size='s'>
								{t('billing.storage')}:{' '}
							</Text>
							<Text weight='bold' view='primary' size='s'>
								<CurrencyValue value={spacePrice} currency='RUB' />
							</Text>
						</div>
					}
					value={discSpace.update}
					onChange={onChangeSpace}
					className='billing-spaces-input'
					disabled={isPaymentWaitingMode}
				/>
				<Slider
					min={info.isFree ? 1 : 2}
					max={100}
					step={1}
					label={
						<div className='billing-space-line-wrapper'>
							<Text view='primary' size='s'>
								{discSpace.start} {t('gb')}
							</Text>
							<Text view='primary' size='s'>
								100 {t('gb')}
							</Text>
						</div>
					}
					caption={
						<Text view='secondary' size='xs'>
							{(info.hdd && info.hdd.usedSpace) || 0} {t('mb')} {t('billing.usedSpace')}
						</Text>
					}
					value={discSpace.update}
					onChange={doChangeSpace}
					disabled={isPaymentWaitingMode}
					className='billing-space-range'
				/>
			</div>
		);
	}
}
