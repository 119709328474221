import React from 'react';
import { Route } from 'react-router-dom';
import {
	FINANCE_MYCOMPANIES_PATH,
	FINANCE_ANALYTICS_PATH,
	FINANCE_OPERATIONS_PATH,
	FINANCE_REPORTS_PATH,
	FINANCE_SETTINGS_PATH,
	FINANCE_OPERATIONS_NEW_PATH,
} from './constants';
import { SwitchWith404 } from '@widgets/PageNotFound/SwitchWith404';
import loadable from '@loadable/component';
import { withTitleAndLink } from 'client/tools/hoc/withTitleAndLink';
import t from 'i18n';
const MyCompanies = loadable(() => import('./mycompanies'));
const Analytics = loadable(() => import('./analytics'));
const Operations = loadable(() => import('./operations'));
const Reports = loadable(() => import('./reports'));
const Settings = loadable(() => import('./settings'));
const OperationsNew = loadable(() => import('./operations-new'));

/**
 * Главный компонент с роутами и компонентами модуля.
 * Также может содержать, например, некую асинхронную логику инициализации.
 */

export default () => (
	<SwitchWith404>
		<Route path={FINANCE_MYCOMPANIES_PATH} component={withTitleAndLink(MyCompanies, { title: t('contragent.myCompanies') })} />
		<Route path={FINANCE_ANALYTICS_PATH} component={withTitleAndLink(Analytics, { title: t('analytics.plural') })} />
		<Route path={FINANCE_OPERATIONS_PATH} component={withTitleAndLink(Operations, { title: t('operation.plural') })} />
		<Route path={FINANCE_OPERATIONS_NEW_PATH} component={withTitleAndLink(OperationsNew, { title: t('operation.plural') })} />
		<Route path={FINANCE_REPORTS_PATH} component={withTitleAndLink(Reports, { title: t('reports.plural') })} />
		<Route path={FINANCE_SETTINGS_PATH} component={withTitleAndLink(Settings, { title: t('settings') })} />
	</SwitchWith404>
);
