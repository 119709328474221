import { observable } from 'mobx';
import store from 'client/store';

export default class ProductStore {
	@observable product = null;
	@observable features = [];
	@observable productFeatures = [];
	@observable nomenclaturesFeatures = [];
	@observable amountRanges = [];
	@observable nomenclatures = [];
	@observable isLoading = true;
	@observable categories = {};

	constructor(productId, categoryParentId) {
		this.categoryParentId = categoryParentId;
		this.productId = productId;
		this.fetchCategory();
		this.init();
	}

	init = async () => {
		if (this.productId) {
			this.product = await store.model.Product.findById(this.productId, { include: ['amountRanges', 'techMaps'] });
			const amountRanges = this.product.amountRanges();

			this.amountRanges = amountRanges.sort((a, b) => {
				const _a = a.amountFrom || 0 + a.amountTo || 0;
				const _b = b.amountFrom || 0 + b.amountTo || 0;
				return _a - _b;
			});

			await this.loadProductFeatures();
			await this.loadVariantsFeatures();
			await this.loadVariants();
		} else {
			this.product = new store.model.Product();
			this.product.typeId = 1;
			if (this.categoryParentId) this.product.categoryId = this.categoryParentId;
		}

		this.isLoading = false;
	};

	fetchCategory = async () => {
		const categories = await store.model.ViewProductCategory.find();

		this.categories = categories.reduce((acc, currentItem) => {
			acc[currentItem.id] = currentItem;
			return acc;
		}, {});
	};

	loadProductFeatures = async () => {
		this.productFeatures = await store.model.ProductFeature.find({
			where: { productId: this.productId, featureId: { neq: null } },
			include: [
				{
					relation: 'feature',
					scope: {
						include: ['featureValues'],
						// where: { featureType: 'product' },
					},
				},
			],
			order: 'weight desc',
		});
	};

	loadVariantsFeatures = async () => {
		this.nomenclaturesFeatures = await store.model.ProductNomenclatureFeature.find({
			where: { productId: this.productId, featureId: { neq: null } },
			include: [
				{
					relation: 'feature',
					scope: {
						include: ['featureValues'],
						// where: { featureType: 'nomenclature' },
					},
				},
			],
			order: 'weight desc',
		});
	};

	loadVariants = async () => {
		this.nomenclatures = await store.model.Nomenclature.find({
			where: {
				productId: this.productId,
			},
			include: [
				{
					relation: 'featuresValues',
					scope: {
						include: [
							{
								relation: 'feature',
								scope: {
									include: ['featureValues'],
								},
							},
							{ relation: 'featureValue' },
						],
					},
				},
				{
					relation: 'prices',
					scope: {
						order: 'rangeId asc',
					},
				},
			],
			order: 'id desc',
		});
	};

	toggleVariantFeature = async (features, feature) => {
		const values = await feature.featureValues();
		const variantFeatures = [...this.nomenclaturesFeatures];
		const index = variantFeatures.findIndex((variantFeature) => variantFeature.featureId === feature.id);
		if (index === -1) {
			const newVariantFeature = new store.model.ProductNomenclatureFeature();
			newVariantFeature.productId = this.productId;
			newVariantFeature.feature = feature;
			await newVariantFeature.save();
			variantFeatures.push(newVariantFeature);
			variantFeatures.sort((a, b) => a.productId - b.productId);
		} else {
			const { featureId } = variantFeatures[index];

			// подчищение удаленных значений характеристик у вариантов
			for (const variant of this.nomenclatures) {
				const featuresValues = variant.featuresValues();
				for (const featuresValue of featuresValues) {
					if (featuresValue.featureId === featureId) {
						featuresValue.delete();
					}
				}
			}

			await variantFeatures[index].delete();
			variantFeatures.splice(index, 1);
		}
		this.nomenclaturesFeatures = [...variantFeatures];
	};

	toggleProductFeature = async (features, feature) => {
		const values = await feature.featureValues();
		const productFeatures = [...this.productFeatures];
		const index = productFeatures.findIndex((productFeature) => productFeature.featureId === feature.id);
		if (index === -1) {
			const newProductFeature = new store.model.ProductFeature();
			newProductFeature.productId = this.productId;
			newProductFeature.feature = feature;
			await newProductFeature.save();
			productFeatures.push(newProductFeature);
			productFeatures.sort((a, b) => a.productId - b.productId);
		} else {
			await productFeatures[index].delete();
			productFeatures.splice(index, 1);
		}
		this.productFeatures = [...productFeatures];
	};

	createRange = async () => {
		const newRange = new store.model.ProductAmountRange();
		newRange.productId = this.productId;
		newRange.amountFrom = this.amountRanges.length ? this.amountRanges[this.amountRanges.length - 1]?.amountTo + 1 : 1;
		this.amountRanges.push(newRange);
	};
}
