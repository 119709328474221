import React from 'react';
import { observer } from 'mobx-react';
import t from 'i18n';
import { RecordSelect } from '@smartplatform/consta/ui/RecordSelect';
import './ToolbarRecordSelect.scss';
import classNames from 'classnames';
import { Text } from '@consta/uikit/Text';

@observer
export class ToolbarRecordSelect extends React.Component {
	static defaultProps = {
		property: 'name',
	};
	constructor(props) {
		super(props);
		this.placeholder = props.placeholder;
		if (props.multiple && !props.renderValue) {
			this.renderValue = this.multipleRenderValue;
			this.placeholder = this.placeholder || t('select');
		} else {
			this.renderValue = props.renderValue;
		}
	}

	multipleRenderValue = ({ item }) => {
		const value = this.props.value;
		const index = value.findIndex(({ id }) => id === item.id);

		if (index === 0) {
			if (value.length === 1) {
				return <Text truncate>{item[this.props.property]}</Text>;
			}
			return `${this.props.selectedPlaceholder || t('selected')}: ${value.length}`;
		}

		return null;
	};

	render() {
		const { renderValue, placeholder } = this;
		const { selectedPlaceholder, ...restComboboxProps } = this.props;
		const className = classNames('ToolbarRecordSelect', this.props.className);
		return <RecordSelect {...restComboboxProps} renderValue={renderValue} className={className} placeholder={placeholder} />;
	}
}

