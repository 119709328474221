export const MODULES = [
	{ name: 'management', title: 'userLogs.modules.management' },
	{ name: 'sales', title: 'userLogs.modules.sales' },
	{ name: 'finance', title: 'userLogs.modules.finance' },
	{ name: 'storehouse', title: 'userLogs.modules.storehouse' },
	{ name: 'manufacture', title: 'userLogs.modules.manufacture' },
	{ name: 'documents', title: 'userLogs.modules.documents' },
];
export const YEAR = { title: 'year', value: 12 };
export const QUARTER = { title: 'quarter', value: 3 };
export const MONTH = { title: 'month', value: 1 };
export const PERIODS = [YEAR, QUARTER, MONTH];