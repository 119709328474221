import React from 'react';
import { Button } from '@consta/uikit/Button';
import chatsCircle from '@phosphor-icons/core/regular/chats-circle.svg';

import store from 'client/store';
import './feedback-button.scss';

export class FeedbackButton extends React.Component {
	showFeedbackPopup = () => (store.ui.showFeedbackPopup = true);

	render() {
		return (
			<Button 
				className='wrapper-feedback-button' 
				onClick={this.showFeedbackPopup} 
				iconLeft={chatsCircle}
				onlyIcon
			/>
		);
	}
}

