import { Status } from './Status';

// вспомогательный компонент для StatusSelect
export const renderStatus = (status, options = {}) => {
	if (!status) return;
	const props = {};

	if (status.item) {
		// renderValue
		const { item, handleRemove } = status;
		props.status = item;
		//! тут надо будет дописать логику крестик
		// props.onCancel = handleRemove;
	} else {
		// getItemLabel
		props.status = status;
	}

	const size = options.size || 'm';
	return <Status key={props.status.id} size={size} truncate={options.truncate} color={props.status.color} name={props.status.name} />;
};

