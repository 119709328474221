import React from 'react';
import Root from './Root';
import t from 'i18n';
import { MESSAGES_DIALOG_PATH, MESSAGES_SETTINGS_PATH } from '@pages/messages/constants';
import { BadgeTitle } from 'components';
import DialogsIcon from '@phosphor-icons/core/regular/chats-circle.svg';
import SettingsIcon from '@phosphor-icons/core/regular/sliders-horizontal.svg';

export default {
	/** Заголовок модуля */
	title: t('messages.title'),

	/** Метка для модуля deprecated|beta */
	type: 'beta',

	/** Короткий заголовок модуля */
	titleShort: t('messages.title'),

	/** Код модуля на латинице, обязателен */
	code: 'messages',

	/** Опциональная дополнительная функция проверки доступности модуля */
	enabled: (store) => store.model.Dialog && store.model.Dialog.INFO.READ,

	/** Главный компонент с роутами и компонентами модуля */
	component: Root,

	menu: () => [
		{
			type: 'item',
			label: t('dialog.plural'),
			path: MESSAGES_DIALOG_PATH,
			icon: () => <DialogsIcon size='m' />,
			badgeFunc: async (store) =>
				await store.model.ViewComment.count({
					dialogId: { neq: null },
					or: [{ hasRead: false }, { hasRead: null }],
				}),
			badgeEvent: 'DialogMessageCount',
		},
		{
			type: 'item',
			label: t('settings'),
			path: MESSAGES_SETTINGS_PATH,
			icon: () => <SettingsIcon size='m' />,
			enabled: (store) =>
				(store.model.DialogSource && store.model.DialogSource.INFO.WRITE) ||
				(store.model.DialogStatus && store.model.DialogStatus.INFO.WRITE),
		},
	],
};
