import { ContextMenu } from '@consta/uikit/ContextMenu';
import { UserAvatar } from '@smartplatform/consta/ui/UserAvatar';
import React from 'react';
import store from 'client/store.js';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import t from 'i18n';
import ProfileIcon from '@phosphor-icons/core/regular/user-gear.svg';
import SupportIcon from '@phosphor-icons/core/regular/question.svg';
import SignOutIcon from '@phosphor-icons/core/regular/sign-out.svg';
import HelpIcon from '@phosphor-icons/core/regular/book-open-text.svg';
import './UserWithContextMenu.scss';

const HELP_END_PATH = {
	ru: '/ru-help/',
	en: '/en/help/',
};

@observer
export class UserWithContextMenu extends React.Component {
	@observable isUserContextOpen = false;
	ref = React.createRef();
	helpUrl = 'https://smart-erp.pro';
	goTo = (path) => () => store.route.push({ path });
	openUserContext = () => (this.isUserContextOpen = true);
	closeUserContext = () => (this.isUserContextOpen = false);
	logout = () => store.model.logout();
	toggleFeedbackPopup = () => {
		store.ui.showFeedbackPopup = !store.ui.showFeedbackPopup;
	};
	onUserContextSelect = (item) => {
		this.closeUserContext();
		item.onClick();
	};
	openHelp = () => {
		const { code } = store.model.user?.language || {};
		window.open(this.helpUrl + HELP_END_PATH[code || 'ru']);
	};

	userContextItems = [
		{
			label: t('user.profile'),
			onClick: this.goTo('/profile'),
			leftIcon: ProfileIcon,
		},
		{
			label: t('help'),
			onClick: this.openHelp,
			leftIcon: HelpIcon,
		},
		{
			label: t('support'),
			onClick: this.toggleFeedbackPopup,
			leftIcon: SupportIcon,
		},
		{
			label: t('logout'),
			onClick: this.logout,
			leftIcon: SignOutIcon,
		},
	].filter(Boolean);

	render() {
		const { openUserContext, ref, isUserContextOpen, userContextItems, closeUserContext, onUserContextSelect } = this;
		return (
			<>
				<UserAvatar
					ref={ref}
					user={store.model.user}
					onClick={openUserContext}
					withArrow
					fioShort
					className='UserWithContextMenu'
				/>
				<ContextMenu
					isOpen={isUserContextOpen}
					items={userContextItems}
					anchorRef={ref}
					direction='downStartRight'
					onClickOutside={closeUserContext}
					getItemOnClick={onUserContextSelect}
					className='UserWithContextMenu-dropdown'
				/>
			</>
		);
	}
}
