import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { DragNDropField, getErrorsList } from '@consta/uikit/DragNDropFieldCanary';
import { Picture } from '@smartplatform/consta/ui/Picture';
import NoImage from 'client/img/no-image.svg';
import { DeleteIconButton } from 'components';
import { withTranslation } from 'react-i18next';
import './ImageUploader.scss';
import { addResourceBundle } from './i18n';

addResourceBundle();

@withTranslation('components', { keyPrefix: 'ImageUploader' })
@observer
export class ImageUploader extends React.Component {
	@observable image = null;
	@observable isChange = false;

	constructor(props) {
		super(props);
		this.init();
	}

	init = () => {
		const { __data, record, property, maxSize, t } = this.props;

		if (__data) {
			this.record = __data.record;
			this.property = __data.property;
			this.form = __data.form;
		} else {
			this.record = record;
			this.property = property;
		}

		if (this.record[this.property]) {
			this.image = this.record.downloadFile(this.property);
		}

		const maxSizeMB = maxSize / 1024 / 1024;

		this.DragNDropFieldProps = {
			onDrop: this.onDropFiles,
			multiple: false,
			className: 'ImageUploader',
			accept: {
				'image/png': ['.png'],
				'image/jpeg': ['.jpeg', '.jpg'],
				'image/webp': ['.webp'],
			},
			onDropRejected: this.onDropRejected,
			onError: this.onError,
		};

		if (maxSize) {
			this.DragNDropFieldProps.maxSize = maxSize;
		}

		this.locale = { 'file-too-large': t('error.maxSize', { maxSize: maxSizeMB }) };
	};

	onDropFiles = async (acceptedFiles) => {
		const file = acceptedFiles[0];

		if (file) {
			const reader = new FileReader();
			reader.onload = () => {
				const imageDataUrl = reader.result;
				this.image = imageDataUrl;
				this.isChanged = true;
			};

			reader.readAsDataURL(file);

			if (this.props.__data) {
				if (this.record.id) {
					await this.record.patchAttributes({ [this.property]: file.name });
					await this.record.uploadFile(this.property, file);
				} else {
					if (this.props.onChange) this.props.onChange(file.name);
					if (this.form && this.form.setFile) {
						this.form.setFile({
							file: file,
							property: this.property,
						});
					}
				}
			} else {
				this.record[this.property] = file.name;
				if (this.props.onChange) this.props.onChange(file, this.record);
			}
		}
	};

	deleteImage = async () => {
		if (this.record.id) {
			await this.record.patchAttributes({ [this.property]: null });
			await this.record.deleteFile(this.property);
		} else {
			if (this.props.onChange) this.props.onChange();
			this.record[this.property] = null;
			if (this.form && this.form.removeFile) this.form.removeFile(this.property);
		}
		this.image = null;
	};

	onDropRejected = (fileRejections) => {
		if (fileRejections) {
			const error = getErrorsList(fileRejections, this.locale)[0];
			this.onError(error);
		}
	};

	onError = (error) => {
		if (this.props.onError) this.props.onError(error);
	};

	render() {
		const { t } = this.props;
		const { image, isChanged } = this;
		return (
			<DragNDropField {...this.DragNDropFieldProps}>
				{({ openFileDialog }) => (
					<>
						<p className='ImageUploader-placeholder' onClick={openFileDialog}>
							{t('moveOrSelectFile')}
						</p>
						{image ? (
							<>
								<DeleteIconButton
									onConfirm={this.deleteImage}
									popPosition='top'
									className='DeleteIconButton'
									portalClassName='popup-confirm-uploader'
									noBorder
								/>
								{isChanged ? <img src={image} alt='image' loading='lazy' /> : <Picture src={image} width={200} />}
							</>
						) : (
							<NoImage onClick={openFileDialog} />
						)}
					</>
				)}
			</DragNDropField>
		);
	}
}
