import React from 'react';
import { observer } from 'mobx-react';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import 'ag-grid-community/styles/ag-grid.css'; // Mandatory CSS required by the Data Grid
import 'ag-grid-community/styles/ag-theme-quartz.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import appStore from 'client/store';
import classNames from 'classnames';
// импорт не удалять, именно так происходит инициализация
import * as ag from 'ag-grid-enterprise';
import { dataTypeDefinitions, getSideBar, LOCALE_MAP, getDefaultColumnDefs } from './params';
import { getSameDateFilter } from 'components/ag-grid/helpers';
import { dateTimeToLocaleString, dateToLocaleString } from 'client/tools/locale';
import './agGrid.scss';
import { DEFAULT_THEME, ARRAY_COL_DEFS, ID_COL_DEFS, THEMES_CLASSNAMES } from './constants';

@observer
export class AgGrid extends React.Component {
	static defaultProps = {
		width: '100%',
		height: '100%',
		rowGroupPanelShow: 'always', // включает верхнее меню для группирвки
		pivotPanelShow: 'always', // включает верхнее меню для столбцов
		pagination: true,
		paginationPageSize: 100,
		paginationPageSizeSelector: [100, 500, 1000],
		debug: true,
		enableRangeSelection: true, // диапозонный селект данных в табличке
		templateCode: null, // Шаблонный код активирует панель с шаблонами, использует модель AgGridTemplate
		rowData: [], // данные строк
		columnDefs: [], // объявленные столбцы
		loading: null, // индикация loading пока загружаются данные
		animateRows: true,
		convertCols: true, // дефолтное конвертирование типов столбцов
	};

	// чтобы не повторять типичные пропы в каждой табличке
	get columnDefs() {
		if (!this.props.convertCols) return this.props.columnDefs;
		const columns = this.props.columnDefs.map((col) => {
			// пропы для столбцов дат. getSameDateFilter позволяет искать даты с тем же днем,  dateTimeToLocaleString - дата со временем, dateToLocaleString дата без времени
			if (['date', 'dateTime'].includes(col.cellDataType)) {
				col.filter = 'agDateColumnFilter';
				col.filterParams = { ...getSameDateFilter() };
				col.valueFormatter = ({ value }) =>
					col.cellDataType === 'date' ? dateToLocaleString(value) : dateTimeToLocaleString(value);
			}
			if (col.cellDataType === 'id') {
				col = { ...col, ...ID_COL_DEFS };
			}
			if (col.cellDataType === 'array') {
				col = { ...col, ...ARRAY_COL_DEFS };
			}
			return col;
		});
		return columns;
	}

	gridRef = React.createRef();
	sideBar = getSideBar({ gridRef: this.gridRef, templateCode: this.props.templateCode });

	render() {
		let { className, height, width, templateCode, ...restProps } = this.props;
		const themeClassName = THEMES_CLASSNAMES[appStore.local.agGrid?.theme] || THEMES_CLASSNAMES[DEFAULT_THEME];
		className = classNames(
			'ag-grid-wrapper',
			appStore.local.theme !== 'DEFAULT' ? `${themeClassName}-dark` : `${themeClassName}`,
			className
		);

		return (
			<div className={className} style={{ width, height }}>
				<AgGridReact
					defaultColDef={getDefaultColumnDefs()}
					dataTypeDefinitions={dataTypeDefinitions} // перезаписывание поведения типов данных
					localeText={LOCALE_MAP[appStore.local.language]} // локалиация
					sideBar={this.sideBar} // кастомный сайдбар
					ref={this.gridRef} // ref для получения доступа к апи
					{...restProps}
					columnDefs={this.columnDefs}
				/>
			</div>
		);
	}
}

