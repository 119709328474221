import { DndContext } from '@smartplatform/ui';
import './styles/main.scss';
// не убирать! после билда необходимо чтобы favicon оставалась в статике
import favicon from 'client/img/favicon.ico?url';
import { AppRouter } from './AppRouter';
import { ConstaTheme } from './hocs/ConstaTheme';
import { ErrorBoundary } from './hocs/ErrorBoundary';
import { ModelStoreErrorBoundary } from './hocs/ModelStoreErrorBoundary';

import store from '@appStore';

export const App = () => {
	// как уберем smartplatform/ui можно убрать и DndContext
	return (
		<ErrorBoundary>
			<ConstaTheme>
				<DndContext>
					<ModelStoreErrorBoundary>
						<AppRouter key={store.appKey} />
					</ModelStoreErrorBoundary>
				</DndContext>
			</ConstaTheme>
		</ErrorBoundary>
	);
};
