import React from 'react';
import { observer } from 'mobx-react';
import { Mobile, NotPhone, Phone } from 'components';
import { FeedbackPopup } from '@widgets/FeedbackPopup';
import store from 'client/store';
import { SideBar } from '@widgets/SideBar';
import { Header } from '@widgets/Header';
import './style.scss';
import { MobileHeader } from '@widgets/MobileHeader';

@observer
export class MainLayout extends React.Component {
	render() {
		return (
			<div className='MainLayout'>
				<NotPhone>
					<SideBar />
				</NotPhone>
				<div className='MainLayout-right'>
					<NotPhone>
						<Header />
					</NotPhone>
					<Phone>
						<MobileHeader />
					</Phone>
					<div className='MainLayout-content'>
						<div className='MainLayout-page'>
							{store.ui.title && (
								<Mobile>
									<div className='page-title'>{store.ui.title || ''}</div>
								</Mobile>
							)}
							{this.props.children}
						</div>
					</div>
				</div>
				<FeedbackPopup isOpen={store.ui.showFeedbackPopup} />
			</div>
		);
	}
}
