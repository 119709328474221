import React from 'react';
import { Provider, observer } from 'mobx-react';
import { NotPhone } from 'components';
import { BillingButton } from './BillingButton/BillingButton';
import { BillingModal } from './BillingModal/BillingModal';
import { BillingModalConfirms } from './BillingModalConfirms/BillingModalConfirms';
import { BillingStore } from './store';

@observer
export class Billing extends React.Component {
	store = null;

	constructor(props) {
		super(props);
		this.store = new BillingStore();
	}

	render() {
		if (!this.store.isBilling) return null;

		return (
			<Provider context={this.store}>
				<NotPhone>
					<BillingButton />
				</NotPhone>
				<BillingModal />
				<BillingModalConfirms />
			</Provider>
		);
	}
}
