import { UserAvatar } from '@smartplatform/consta/ui/UserAvatar';
import store from '@appStore';
import t from 'i18n';
export const User = (props) => {
	let user = props.user;
	if (!props.user) return;

	//? опционально может сделать?
	if (store.model.user.id === props.user.id) {
		const name = props.meLabel || t('me');
		user = new store.model.User({ avatar: store.model.user.avatar, id: store.model.user.id, lastName: name });
	}
	return <UserAvatar {...props} user={user} view='clear' fioShort />;
};
