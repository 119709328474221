import React from 'react';
import { Toolbar } from 'components';
import { inject, observer } from 'mobx-react';
import { Checkbox } from '@smartplatform/consta/ui/Checkbox';
import t from 'i18n';
import './style.scss';
import { MODES } from '@shared/constants';
import { ModeSwitcher } from '@features/ModeSwitcher';

@inject('store')
@observer
export class DialogFilters extends React.Component {
	constructor(props) {
		super(props);
		this.store = props.store;
	}

	modes = [MODES.LIST, MODES.KANBAN];
	render() {
		const { source, onChange, hideSpam, itemsSource, mode, onChangeMode } = this.store;
		const buttons = [{ label: t('all'), value: null }, ...itemsSource];
		const filterFields = (
			<Toolbar>
				<Toolbar.LeftSide>
					<Toolbar.ButtonGroup
						items={buttons}
						onChange={onChange('source')}
						getItemLabel={(item) => item.label}
						value={buttons.find((item) => item.value === source)}
					/>
					<Checkbox onChange={onChange('hideSpam')} value={hideSpam} label={t('dialog.hideSpan')} />
				</Toolbar.LeftSide>
				<Toolbar.RightSide>
					<ModeSwitcher selected={mode} modes={this.modes} onChange={onChangeMode} />
				</Toolbar.RightSide>
			</Toolbar>
		);

		return <div className='wrapper-dialogs-filters'>{filterFields}</div>;
	}
}

