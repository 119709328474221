import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { RenewAbortController } from '@smartplatform/consta/lib/network';
import store from 'client/store';
import { SearchResultItem } from '@features/SearchResultItem/SearchResultItem';
import t from 'i18n';
import { debounce, lowerFirst } from 'lodash';
import { AutoComplete } from '@consta/uikit/AutoComplete';

const MODEL_ROUTES = {
	Product: (item) => ({ path: `/sales/catalog/product/${item.id}` }),
	ProductCategory: (item) => ({ path: `/sales/catalog`, params: { categoryParentId: item.id } }),
};

const MODELS_SEARCH_PROPS = {
	Product: {
		searchFields: ['name'],
	},
	ProductCategory: {
		searchFields: ['name'],
	},
};

@observer
export class SearchCatalog extends React.Component {
	@observable items = [];
	@observable value = null;
	@observable isLoading = false;

	abortController = new RenewAbortController();

	constructor(props) {
		super(props);
		this.onSearch = debounce(this.onSearch, 500, { leading: false, trailing: true });
	}

	onSearch = async () => {
		if (this.value) {
			try {
				this.isLoading = true;
				this.abortController.abort();
				const params = { search: this.value, models: MODELS_SEARCH_PROPS };
				const res = await fetch(`/api/search-model-records?params=${encodeURIComponent(JSON.stringify(params))}`, {
					signal: this.abortController.signal,
				});
				const resultItems = (this.searchResult = await res.json());
				this.items = resultItems.map((r) => ({
					label: r.record.name,
					modelName: r.modelName,
					id: r.id,
				}));
				this.isLoading = false;
			} catch (error) {
				console.error(error);
			}
		}
	};

	renderItem = ({ item }) => (
		<SearchResultItem onClick={() => this.onClickItem(item)} label={item.label} caption={t(`${lowerFirst(item.modelName)}.title`)} />
	);

	onClickItem = (item) => {
		if (MODEL_ROUTES.hasOwnProperty(item.modelName)) {
			store.route.push(MODEL_ROUTES[item.modelName](item));
		}
	};

	onChangeValue = async (value) => {
		this.value = value;
		await this.onSearch();
	};

	render() {
		return (
			<div className='SearchCatalog'>
				<AutoComplete
					items={this.items}
					renderItem={this.renderItem}
					type='text'
					placeholder={t('search')}
					value={this.value}
					onChange={this.onChangeValue}
					isLoading={this.isLoading}
				/>
			</div>
		);
	}
}

