import { observable } from 'mobx';
import store from '@appStore';

const SETTINGS = ['showDocumentCustomFields'];

class documentsStore {
	@observable showDocumentCustomFields = false;
	@observable isInitialized = false;

	constructor() {
		this.init();
	}

	init = () => {
		this.moduleServerConfig = store.model.config.modules.find((module) => module?.name === 'documents') || {};
		const moduleConfig = store.mergedConfig.modules.find((module) => module?.name === 'documents') || {};

		Object.entries(moduleConfig).forEach(([key, value]) => {
			if (SETTINGS.includes(key)) {
				value = ['false', 'true'].includes(value) ? Boolean(value) : value;
				if (this[key] !== undefined) {
					this[key] = value;
				}
			}
		});

		this.isInitialized = true;
	};
}

export default new documentsStore();

