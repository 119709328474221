import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { AnimateIconSwitcherProvider } from '@consta/icons/AnimateIconSwitcherProvider';
import { withAnimateSwitcherHOC } from '@consta/icons/withAnimateSwitcherHOC';
import AscendingIcon from '@phosphor-icons/core/regular/sort-ascending.svg';
import DescendingIcon from '@phosphor-icons/core/regular/sort-descending.svg';
import { Button } from '@consta/uikit/Button';

import './SortSwitcher.scss';

const SortIcon = withAnimateSwitcherHOC({
	startIcon: AscendingIcon,
	endIcon: DescendingIcon,
});

@observer
export class SortSwitcher extends Component {
	render() {
		const { active, onClick, size } = this.props;

		return (
			<AnimateIconSwitcherProvider active={active}>
				<Button size={size} onClick={onClick} view='clear' form='round' iconLeft={SortIcon} onlyIcon />
			</AnimateIconSwitcherProvider>
		);
	}
}
