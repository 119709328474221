import React from 'react';
import { inject, observer } from 'mobx-react';
import { Modal } from '@consta/uikit/Modal';
import { Text } from '@consta/uikit/Text';
import { ChoiceGroup } from '@consta/uikit/ChoiceGroup';
import { Loader } from '@consta/uikit/Loader';
import { Switch } from '@smartplatform/consta/ui/Switch';
import { PERIODS } from '../constants';
import { BillingHeader } from '../BillingHeader/BillingHeader';
import { BillingModules } from '../BillingModules/BillingModules';
import { BillingSpaces } from '../BillingSpaces/BillingSpaces';
import { BillingTotal } from '../BillingTotal/BillingTotal';

import store from 'client/store';
import { t } from 'i18next';
import './BillingModal.scss';

@inject('context')
@observer
export class BillingModal extends React.Component {
	getItemLabel = (item) => t(item.title);

	render() {
		const { props, getItemLabel } = this;
		const {
			closePopup,
			closePopupOutside,
			info,
			period,
			onChangePeriod,
			changeTariffPayment,
			isPaidTariff,
			isChangeSpace,
			discSpace,
			onChangeTariff,
			total,
			isTariffChange,
			isLoading,
		} = props.context;

		const isPaymentWaitingMode = changeTariffPayment && !isPaidTariff;
		let tariff = 'free';
		if ((isChangeSpace && discSpace.update > 1 && info.isFree) || !info.isFree) {
			tariff = 'smart';
		}
		const isChangeTariff = isChangeSpace && discSpace.update > 1;

		// унести вычисления totalCost на бек
		const buyingSpace = discSpace.update - (info.isFree ? 2 : discSpace.start);
		const pricePerGigabyte = info.modulesPrice && parseFloat(info.modulesPrice[tariff].space[period.value]);
		const spacePrice = discSpace.update > discSpace.start ? buyingSpace * pricePerGigabyte : 0;
		const totalCost = (isTariffChange || isChangeSpace) ? total['editTariff'] : total[period.title];

		return (
			<Modal isOpen={store.ui.showPopupBilling} onClickOutside={closePopupOutside} onEsc={closePopup} className='BillingModal' hasOverlay={false}>
				{isLoading ? <Loader /> : <>
					<BillingHeader />
					<div className='billing-body'>
						<ChoiceGroup
							value={period}
							items={PERIODS}
							width='full'
							getItemLabel={getItemLabel}
							onChange={onChangePeriod}
							disabled={isPaymentWaitingMode}
							multiple={false}
						/>
						<BillingModules isPaymentWaitingMode={isPaymentWaitingMode} />
						{info.isFree && (
							<Switch
								onChange={onChangeTariff}
								value={isChangeTariff}
								disabled={isPaymentWaitingMode}
								label={<Text size='s'>{t('billing.removeUserLimitRestrictions', { limitUsers: info.limitUsers })}</Text>}
							/>
						)}
						<BillingSpaces spacePrice={spacePrice} isPaymentWaitingMode={isPaymentWaitingMode} />
						<BillingTotal totalCost={totalCost} isPaymentWaitingMode={isPaymentWaitingMode} />
					</div>
				</>}
			</Modal>
		);
	}
}
