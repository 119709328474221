import React from 'react';
import { Button } from '@consta/uikit/Button';
import { AnimateIconSwitcherProvider } from '@consta/icons/AnimateIconSwitcherProvider';
import { withAnimateSwitcherHOC } from '@consta/icons/withAnimateSwitcherHOC';
import { IconArrowPrevious } from '@consta/icons/IconArrowPrevious';
import { IconArrowNext } from '@consta/icons/IconArrowNext';
import { classnames } from '@bem-react/classnames';
import './SidebarSwitcher.scss';

const MyAnimateIcon = withAnimateSwitcherHOC({
	startIcon: IconArrowPrevious,
	endIcon: IconArrowNext,
});

export const SidebarSwitcher = ({ onClick, className, fullMode }) => {
	className = classnames('SidebarSwitcher', className);
	return (
		<AnimateIconSwitcherProvider active={fullMode}>
			<Button size='s' view='secondary' onClick={onClick} iconLeft={MyAnimateIcon} onlyIcon className={className} />
		</AnimateIconSwitcherProvider>
	);
};
