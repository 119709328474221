import { Label } from './Label';

// функция рендера для компонента Combobox
export const renderLabel = (label, size = 'm') => {
	let onCancel;

	if (label?.item) {
		onCancel = label.handleRemove;
		label = label.item;
	}

	return <Label key={label.id} label={label} size={size} onCancel={onCancel} />;
};

