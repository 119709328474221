import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { PERIODS } from '@shared/constants';
import t from 'i18n';

import {
	endOfDay,
	startOfDay,
	startOfWeek,
	endOfWeek,
	startOfMonth,
	endOfMonth,
	startOfYear,
	endOfYear,
	startOfQuarter,
	endOfQuarter,
} from 'date-fns';
import { addMonths, addDays } from 'date-fns';
import './dateRangePicker.scss';
import CaretLeftIcon from '@phosphor-icons/core/regular/caret-left.svg';
import CaretRightIcon from '@phosphor-icons/core/regular/caret-right.svg';
import { Button } from '@consta/uikit/Button';
import { Select } from '@consta/uikit/Select';
import { DatePicker } from '@consta/uikit/DatePicker';

@observer
export class DateRangePicker extends React.Component {
	periodDate = new Date();

	static propTypes = {
		dateValues: PropTypes.shape({
			startDate: PropTypes.instanceOf(Date),
			endDate: PropTypes.instanceOf(Date),
			period: PropTypes.string,
			periodDate: PropTypes.instanceOf(Date),
		}).isRequired,
		onChange: PropTypes.func,
		className: PropTypes.string,
	};

	static defaultProps = {};

	onDateChange = (prop) => (date) => {
		const map = {
			startDate: () => (date ? startOfDay(new Date(date)) : null),
			endDate: () => (date ? endOfDay(new Date(date)) : null),
		};
		this.props.dateValues.period = null;
		this.props.dateValues[prop] = map[prop]?.() || null;
		this.props.onChange?.(this.props.dateValues);
	};

	onPeriodChange = ({ value }) => {
		this.props.dateValues.period = value;
		this.props.dateValues.periodDate = new Date();
		this.setPeriodDates();
		this.props.onChange?.(this.props.dateValues);
	};

	renderPlaceholder = (prop) => t('dateRangePicker.' + prop);

	go = (direction = 1) => {
		const { period, periodDate } = this.props.dateValues;
		if (period) {
			const map = {
				[PERIODS.DAY]: () => addDays(periodDate, direction),
				[PERIODS.WEEK]: () => addDays(periodDate, 7 * direction),
				[PERIODS.MONTH]: () => addMonths(periodDate, direction),
				[PERIODS.QUARTER]: () => addMonths(periodDate, 3 * direction),
				[PERIODS.YEAR]: () => addMonths(periodDate, 12 * direction),
			};
			this.props.dateValues.periodDate = map[period]?.();
		}

		this.setPeriodDates();
	};

	setPeriodDates = () => {
		const { dateValues, onChange } = this.props;
		const { period, periodDate } = dateValues;

		if (period) {
			const map = {
				[PERIODS.DAY]: () => [startOfDay(periodDate), endOfDay(periodDate)],
				[PERIODS.WEEK]: () => [startOfWeek(periodDate, { weekStartsOn: 1 }), endOfWeek(periodDate, { weekStartsOn: 1 })],
				[PERIODS.MONTH]: () => [startOfMonth(periodDate), endOfMonth(periodDate)],
				[PERIODS.QUARTER]: () => [startOfQuarter(periodDate), endOfQuarter(periodDate)],
				[PERIODS.YEAR]: () => [startOfYear(periodDate), endOfYear(periodDate)],
			};
			const [startDate, endDate] = map[period]?.();
			dateValues.startDate = startDate;
			dateValues.endDate = endDate;
		}

		onChange?.(dateValues);
	};

	prev = () => this.go(-1);
	next = () => this.go(1);

	render() {
		let { startDate, endDate, period, periodDate } = this.props.dateValues;
		startDate = startDate ? new Date(startDate) : null;
		endDate = endDate ? new Date(endDate) : null;

		const { onPeriodChange, renderPlaceholder, prev, next, onDateChange } = this;
		const className = classNames('range-date-picker', this.props.className);
		const noPeriod = period === null;
		const prevDisabled = noPeriod;
		const nextDisabled = noPeriod || periodDate >= startOfDay(new Date());
		const periodSelectItems = Object.values(PERIODS).map((mode, index) => ({ label: t(`period.${mode}`), value: mode, id: index }));

		return (
			<div className={className}>
				<DatePicker
					withClearButton
					value={startDate}
					onChange={onDateChange('startDate')}
					placeholder={renderPlaceholder('startDate')}
					maxDate={endDate ? endDate : undefined}
				/>
				<DatePicker
					withClearButton
					value={endDate}
					onChange={onDateChange('endDate')}
					placeholder={renderPlaceholder('endDate')}
					minDate={startDate ? startDate : undefined}
					maxDate={new Date()}
				/>
				<Button onlyIcon iconLeft={CaretLeftIcon} onClick={prev} disabled={prevDisabled} />
				<Select
					getItemLabel={(r) => r.label}
					items={periodSelectItems}
					value={period ? periodSelectItems.find((r) => r.value === period) : null}
					onChange={onPeriodChange}
					placeholder={renderPlaceholder('period')}
				/>
				<Button onlyIcon iconLeft={CaretRightIcon} onClick={next} disabled={nextDisabled} />
			</div>
		);
	}
}

