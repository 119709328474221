import React from 'react';
import { inject, observer } from 'mobx-react';
import { Text } from '@consta/uikit/Text';
import { Button } from '@consta/uikit/Button';
import { Loader } from '@consta/uikit/Loader';
import { Phone, CurrencyValue } from 'components';

import { t } from 'i18next';
import './BillingTotal.scss';

@inject('context')
@observer
export class BillingTotal extends React.Component {
	openConfirmExtend = this.props.context.openConfirm('extend');
	openConfirmModules = this.props.context.openConfirm('modules');

	render() {
		const { props, openConfirmExtend, openConfirmModules } = this;
		const {
			isFree,
			changeTariffPayment,
			cancelPayment,
			closePopup,
			isTariffChange,
			isChangeSpace,
			extendError,
			getChangeTariffAndPay,
		} = props.context;
		const { totalCost, isPaymentWaitingMode } = props;

		const idTryingChangeTariff = isTariffChange || isChangeSpace;

		return (
			<div className='BillingTotal'>
				{isPaymentWaitingMode ? (
					<>
						<div className='billing-waiting-line'>
							{changeTariffPayment && (
								<Text as='a' view='link' href={changeTariffPayment.url} target='_blank'>
									{t('billing.returnToPayment')}
								</Text>
							)}
						</div>
						<div className='billing-waiting-line payment-waiting-text'>
							<Loader size='s' />
							<Text weight='bold' size='s'>
								{t('billing.changeTariffMes')}
							</Text>
						</div>
						<div className='billing-buttons'>
							<Button view='secondary' onClick={cancelPayment} label={t('billing.cancelPayment')} />
							<Phone>
								<Button view='secondary' onClick={closePopup} label={t('close')} />
							</Phone>
						</div>
					</>
				) : (
					<>
						<div className='billing-total-line'>
							<Text weight='bold' view='primary' size='s'>
								{t('billing.total')}:{' '}
							</Text>
							<CurrencyValue value={totalCost} currency='RUB' />
						</div>
						<div className='billing-buttons'>
							{idTryingChangeTariff ? (
								<Button
									onClick={isFree && isChangeSpace ? getChangeTariffAndPay : openConfirmModules}
									label={isFree ? t('change') : t('billing.pay')}
								/>
							) : (
								<Button onClick={openConfirmExtend} label={t('billing.extensionCost')} />
							)}
							<Phone>
								<Button onClick={closePopup} label={t('close')} />
							</Phone>
						</div>
					</>
				)}
				{extendError && (
					<div>
						<Text view='alert'>{extendError.mes}</Text>
						<Text as='a' view='link' onClick={extendError.replenish}>
							{t('billing.extend')}
						</Text>
					</div>
				)}
			</div>
		);
	}
}
