import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import './section.scss';

@observer
export default class Section extends React.Component {
	static propTypes = {
		className: PropTypes.string,
	};

	render() {
		const { children, className, title } = this.props;

		return (
			<section className={'section ' + (className || '')}>
				{title && <header>{title}</header>}
				<div className='content'>{children}</div>
			</section>
		);
	}
}

