import React from 'react';
import { inject, observer } from 'mobx-react';
import { Text } from '@consta/uikit/Text';
import { CurrencyValue } from 'components';
import Check from '@phosphor-icons/core/bold/check-bold.svg';

import classNames from 'classnames';
import { t } from 'i18next';
import './BillingModules.scss';

@inject('context')
@observer
export class BillingModules extends React.Component {
	render() {
		const { modules, isChangeSpace, discSpace, info, period, onChangeModule } = this.props.context;
		const { isPaymentWaitingMode } = this.props;

		let tariff = 'free';
		if ((isChangeSpace && discSpace.update > 1 && info.isFree) || !info.isFree) {
			tariff = 'smart';
		}
		const isDiscount = !(period.value === 1) && tariff === 'smart' && module.cost != 0;

		return (
			<div className='BillingModules'>
				{modules.map((module) => (
					<div
						className={classNames('billing-module', {
							'billing-module-active': module.checked,
							'billing-payment-waiting-mode': isPaymentWaitingMode,
						})}
						onClick={isPaymentWaitingMode ? null : onChangeModule(module.name)}
						key={module.title}
					>
						{module.checked && <Check className='billing-module-icon' />}
						<div className='billing-module-text'>
							<Text weight='bold' transform='uppercase' size='xs'>
								{t(module.title)}
							</Text>
							<div className='billing-module-price-wrapper'>
								<Text weight='bold' size='s' lineHeight='2xs'>
									<CurrencyValue value={info.modulesPrice[tariff][module.name][period.value]} currency='RUB' />
								</Text>
								{isDiscount && (
									<Text as='del' weight='light' view='secondary' size='xs' lineHeight='2xs' decoration='line-through'>
										<CurrencyValue value={module.cost * period.value} currency='RUB' />
									</Text>
								)}
							</div>
						</div>
					</div>
				))}
			</div>
		);
	}
}
