import React from 'react';
import { Text } from '@consta/uikit/Text';
import SearchIcon from '@phosphor-icons/core/regular/magnifying-glass.svg';
import './SearchResultItem.scss';

export class SearchResultItem extends React.Component {
	render() {
		const { onClick, label, caption } = this.props;
		return (
			<div onClick={onClick} className='SearcResultItem'>
				<div className='SearcResultItem-icon'>
					<SearchIcon size='m' />
				</div>
				<div className='SearcResultItem-info'>
					<Text truncate size='s' weight='medium'>
						{label}
					</Text>
					{caption && (
						<Text size='2xs' className='mark'>
							{caption}
						</Text>
					)}
				</div>
			</div>
		);
	}
}
