import React from 'react';
import { Route } from 'react-router-dom';
import loadable from '@loadable/component';
import { DOCUMENTS_PATH, DOCUMENT_SETTINGS_PATH } from './constants';
import { withTitleAndLink } from 'client/tools/hoc/withTitleAndLink';
import t from 'i18n';
import { SwitchWith404 } from '@widgets/PageNotFound/SwitchWith404';

/**
 * Главный компонент с роутами и компонентами модуля.
 * Также может содержать, например, некую асинхронную логику инициализации.
 */

const Documents = loadable(() => import('./documents'));
const Settings = loadable(() => import('./settings'));

export default () => (
	<SwitchWith404>
		<Route path={DOCUMENTS_PATH} component={Documents} />
		<Route path={DOCUMENT_SETTINGS_PATH} component={withTitleAndLink(Settings, { title: t('settings') })} />
	</SwitchWith404>
);

