import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

@observer
export class ToolbarBottom extends Component {
	render() {
		return <div className={classNames('bottom', this.props.className)}>{this.props.children}</div>;
	}
}

