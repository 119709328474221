import React from 'react';
import { observer } from 'mobx-react';
import { Attachment } from '@consta/uikit/Attachment';
import { withTranslation } from 'react-i18next';
import './Attach.scss';
import { addResourceBundle } from './i18n';
import { IconTrash } from '@consta/icons/IconTrash';
import { formatDate } from 'client/tools';

addResourceBundle();

@withTranslation('components', { keyPrefix: 'Attach' })
@observer
export class Attach extends React.Component {
	static defaultProps = {
		property: 'filename',
	};

	constructor(props) {
		super(props);
	}

	onButtonClick = (e) => {
		e.stopPropagation();
		this.props.onButtonClick({ attachment: this.props.attachment, property: this.props.property });
	};

	onClick = () => {
		const link = document.createElement('a');
		link.href = this.props.attachment.downloadFile(this.props.property);
		link.target = '_blank';
		link.click();
		link.remove();
	};
	render() {
		let {
			attachment,
			property,
			size = 'm',
			t,
			loadingText,
			loading,
			loadingProgresss,
			onButtonClick,
			errorText,
			buttonIcon = IconTrash,
			buttonTitle,
		} = this.props;
		if (!attachment) return;

		const fileExtension = attachment[property].split('.').pop();
		const fileDate = formatDate(attachment.createdAt, 'dd.MM.yyyy HH:mm');

		return (
			<Attachment
				className='Attach'
				withPictogram
				size={size}
				fileName={attachment[property]}
				fileExtension={fileExtension}
				fileDescription={fileDate}
				buttonIcon={buttonIcon}
				buttonTitle={buttonTitle || t('delete')}
				onClick={this.onClick}
				onButtonClick={onButtonClick ? this.onButtonClick : undefined}
				errorText={errorText}
				loadingText={loadingText}
				loading={loading}
				loadingProgress={loadingProgresss}
			/>
		);
	}
}
