import React from 'react';
import { observer } from 'mobx-react';
import { UserSelect, renderUser } from '@features/User';
import './ToolbarUserSelect.scss';
import t from 'i18n';
import classNames from 'classnames';

@observer
export class ToolbarUserSelect extends React.Component {
	static defaultProps = {
		size: 'm',
	};
	constructor(props) {
		super(props);
		this.placeholder = props.placeholder;
		if (props.multiple && !props.renderValue) {
			this.renderValue = this.multipleRenderValue;
			this.placeholder = this.placeholder || t('boardList.all');
		} else {
			this.renderValue = props.renderValue;
		}
	}

	multipleRenderValue = ({ item }) => {
		const value = this.props.value;
		const index = value.findIndex(({ id }) => id === item.id);

		if (index === 0) {
			if (value.length === 1) {
				return renderUser(item, { size: this.props.size, width: 'full', meLabel: this.props.meLabel });
			}
			return `${this.props.selectedPlaceholder || t('selected')}: ${value.length}`;
		}

		return null;
	};
	render() {
		const { renderValue, placeholder } = this;
		const { selectedPlaceholder, ...restComboboxProps } = this.props;
		const className = classNames('ToolbarUserSelect', this.props.className);
		return <UserSelect {...restComboboxProps} className={className} renderValue={renderValue} placeholder={placeholder} />;
	}
}

