import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import t from 'i18n';
import { SearchIconInput } from '@features/SearchIconInput';

@observer
export class ToolbarSearch extends React.Component {
	static propTypes = {
		content: PropTypes.any,
		onChange: PropTypes.func, //одинаковые пропы, адаптация под старые компоненты
		onSearch: PropTypes.func, //
		value: PropTypes.string,
		placeholder: PropTypes.string,
		className: PropTypes.string,
	};

	render() {
		let { className, onSearch, onChange, placeholder = t('searchName'), ...restProps } = this.props;
		const _className = classNames('toolbar-search', className);

		// адаптация под старые пропсы
		onChange = onChange || onSearch;

		return <SearchIconInput {...restProps} onChange={onChange} className={_className} />;
	}
}

