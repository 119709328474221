import { Button } from '@consta/uikit/Button';
import PlusIcon from '@phosphor-icons/core/fill/plus-circle-fill.svg';
import React from 'react';
import { withTranslation } from 'react-i18next';
import './i18n';
import classnames from 'classnames';

@withTranslation('components', { keyPrefix: 'PlusIconButton' })
export class PlusIconButton extends React.Component {
	render() {
		let { iconPosition = 'right', label, t, ...restProps } = this.props;
		const icon = PlusIcon;
		const iconProps = {};
		if (iconPosition === 'right') {
			iconProps.iconRight = icon;
		} else if (iconPosition === 'left') {
			iconProps.iconLeft = icon;
		}
		if (!label) {
			label = t('title');
		}

		const className = classnames('PlusIconButton', this.props.className);
		return <Button className={className} label={label} {...iconProps} {...restProps} />;
	}
}
