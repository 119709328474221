import React from 'react';
import { Route } from 'react-router-dom';
import loadable from '@loadable/component';
import { ADMIN_ROLES_PATH, ADMIN_SETTINGS_PATH, ADMIN_USERS_PATH } from './constants';
import t from 'i18n';
import { withTitleAndLink } from 'client/tools/hoc/withTitleAndLink';
import { SwitchWith404 } from '@widgets/PageNotFound/SwitchWith404';

const Users = loadable(() => import('./users'));
const Roles = loadable(() => import('./roles'));
// const Acls = loadable(() => import('./acls'));
const Settings = loadable(() => import('./settings/Settings'));

export default () => (
	<SwitchWith404>
		<Route path={ADMIN_SETTINGS_PATH} component={withTitleAndLink(Settings, { title: t('settings') })} />
		<Route path={ADMIN_USERS_PATH} component={Users} />
		<Route path={ADMIN_ROLES_PATH} component={Roles} />
		{/* <Route path={ADMIN_ACLS_PATH} component={Acls} /> */}
	</SwitchWith404>
);

