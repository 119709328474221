import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import './GlobarSearch.scss';
import { debounce, lowerFirst } from 'lodash';
import store from 'client/store';
import t from 'i18n';
import { RenewAbortController } from '@smartplatform/consta/lib/network';
import { fioOrEmpty } from '@smartplatform/consta/lib/fio';
import { Select } from '@consta/uikit/Select';
import { FieldGroup } from '@consta/uikit/FieldGroup';
import { SearchIconInput } from '@features/SearchIconInput';
import { Popover } from '@consta/uikit/Popover';
import { Loader } from '@consta/uikit/Loader';
import { Text } from '@consta/uikit/Text';
import SearchIcon from '@phosphor-icons/core/regular/magnifying-glass.svg';
import { SearchResultItem } from '@features/SearchResultItem/SearchResultItem';
import classNames from 'classnames';

const AVAILABLE_MODULES = ['management', 'sales', 'documents', 'manufacture', 'admin', 'contragents'];

const MODELS = {
	Task: {
		path: '/management/tasks/',
		renderItem: (r) => r.name,
	},
	Project: {
		path: '/management/projects/',
		renderItem: (r) => r.name,
	},

	Order: {
		path: '/sales/orders/',
		renderItem: (r) => r.description,
	},
	Document: {
		path: '/documents/documents/',
		renderItem: (r) => r.name,
	},
	ProductionOrder: {
		path: '/manufacture/production-orders/',
		renderItem: (r) => r.name,
	},
	User: {
		path: '/admin/users/',
		renderItem: (r) => {
			const user = new store.model.User(r);
			let text = fioOrEmpty(user);
			if (!text) {
				text = user.email || `userId: ${user.id}`;
			}
			return text;
		},
	},
	Contragent: {
		path: '/contragents/contragents/',
		renderItem: (r) => r.fullName || r.name,
	},
};

@observer
export class GlobarSearch extends React.Component {
	@observable search = '';
	@observable showResult = false;
	@observable searchResult = [];
	@observable isLoading = true;
	abortController = new RenewAbortController();
	@observable filterModule = { label: t('globalSearch.all'), id: null };

	constructor(props) {
		super(props);
		this.ref = React.createRef();
		this.doSearch = debounce(this.doSearch, 500, { leading: false, trailing: true });
		this.availableModules = store.availableModules.filter((module) => AVAILABLE_MODULES.includes(module.code));
		this.moduleList = [
			{ label: t('globalSearch.all'), id: null },
			...this.availableModules.map(({ code }, index) => ({ code, label: t('module.' + code + '.title'), id: index + 1 })),
		];
	}

	onSearch = (value) => {
		this.search = value;
		if (!value.trim()) {
			this.showResult = false;
			this.searchResult = [];
		} else this.doSearch();
	};

	doSearch = async () => {
		const search = this.search.trim();
		this.showResult = true;

		if (search) {
			try {
				this.isLoading = true;
				this.abortController.abort();
				let modules = this.availableModules.map((i) => i.code);
				if (this.filterModule.id) {
					modules = [this.filterModule.code];
				}
				const params = { search, modules };
				const res = await fetch(`/api/global-search?params=${encodeURIComponent(JSON.stringify(params))}`, {
					signal: this.abortController.signal,
				});

				this.searchResult = await res.json();
			} catch (e) {
				console.error(e);
			}
			this.isLoading = false;
		}
	};

	onResultItemClick = (item) => {
		this.showResult = false;
		this.searchResult = false;
		this.search = '';
		const path = MODELS[item.model]?.path;
		path && store.route.push({ path: `${path}${item.id}` });
	};

	onInputClick = () => {
		if (this.search.length > 0 && this.searchResult.length > 0) {
			this.showResult = true;
		}
	};
	filterModuleSelect = (value) => {
		this.filterModule = value;
		// триггер фетча при другом значении
		this.onSearch(this.search);
	};

	onActivateFilterModuleSelect = () => {
		this.showResult = false;
	};

	onClickOutside = () => (this.showResult = false);
	onDropdownOpen = () => (this.showResult = false);

	render() {
		const {
			search,
			onSearch,
			showResult,
			searchResult,
			ref,
			onResultItemClick,
			onInputClick,
			isLoading,
			filterModuleSelect,
			filterModule,
			onClickOutside,
			onDropdownOpen,
		} = this;

		const noItems = <div className='not-found'>{t('globalSearch.notFound')}</div>;

		const listItems = isLoading ? (
			<Loader size='s' />
		) : (
			<div className='popover-list'>
				{searchResult?.length > 0
					? searchResult.map((item, index) => {
							const label = MODELS[item.model].renderItem(item.record);
							return (
								<SearchResultItem
									key={index}
									onClick={() => onResultItemClick(item)}
									label={label}
									caption={t(`${lowerFirst(item.model)}.title`)}
								/>
							);
					  })
					: noItems}
			</div>
		);

		const className = classNames('GlobarSearch', this.props.className);

		return (
			<div className={className} ref={ref}>
				<FieldGroup>
					<Select
						items={this.moduleList}
						placeholder={t('globalSearch.all')}
						empty={t('globalSearch.all')}
						value={filterModule}
						onChange={filterModuleSelect}
						disabled={this.availableModules.length < 2}
						view='ghost'
						onDropdownOpen={onDropdownOpen}
					/>
					<SearchIconInput
						position='left'
						placeholder={t('globalSearch.placeholder')}
						value={search}
						onChange={onSearch}
						onClick={onInputClick}
						view='ghost'
					/>
				</FieldGroup>
				{showResult && search?.length > 0 && (
					<Popover
						direction='downCenter'
						className='GlobarSearch-popover SelectDropdown-ScrollContainer'
						isInteractive={true}
						anchorRef={ref}
						offset='2xs'
						onClickOutside={onClickOutside}
						equalAnchorWidth
					>
						{listItems}
					</Popover>
				)}
			</div>
		);
	}
}

