import React from 'react';
import { inject, observer } from 'mobx-react';
import { Button } from '@consta/uikit/Button';
import { Loader } from '@consta/uikit/Loader';
import CoinsIcon from '@phosphor-icons/core/regular/coins.svg';
import { NotPhone, Phone } from 'components';

import classNames from 'classnames';
import { t } from 'i18next';
import './BillingButton.scss';

@inject('context')
@observer
export class BillingButton extends React.Component {
	render() {
		const { refCloseBilling, isLoading, error, info, onTogglePopup } = this.props.context;

		// в рендере так как зависят от изменения isLoading и error
		const label = error ? t('billing.errors.' + error) : info.tariffName;
		const mobileBtnClassNames = classNames('item-account-menu', { disable: isLoading });

		return (
			<>
				<NotPhone>
					<Button
						label={label}
						loading={isLoading}
						size='s'
						onClick={onTogglePopup}
						className='BillingButton'
						ref={refCloseBilling}
						iconLeft={isLoading ? undefined : CoinsIcon}
					/>
				</NotPhone>
				<Phone>
					<div className={mobileBtnClassNames} onClick={onTogglePopup} ref={refCloseBilling}>
						<CoinsIcon size='s' />
						{isLoading ? <Loader size='s' /> : info.tariffName}
					</div>
				</Phone>
			</>
		);
	}
}
