import React from 'react';
import { Route } from 'react-router-dom';
import { DASHBOARD_PATH, PROJECTS_PATH, MANAGEMENT_SETTINGS_PATH, MANAGEMENT_TASKS_PATH } from './constants';
import loadable from '@loadable/component';
import { withTitleAndLink } from 'client/tools/hoc/withTitleAndLink';
import t from 'i18n';
import { SwitchWith404 } from '@widgets/PageNotFound/SwitchWith404';
const Dashboard = loadable(() => import('./dashboard'));
const Tasks = loadable(() => import('./tasks'));
const Settings = loadable(() => import('./settings'));
const Projects = loadable(() => import('./projects'));
/**
 * Главный компонент с роутами и компонентами модуля.
 * Также может содержать, например, некую асинхронную логику инициализации.
 */

export default () => (
	<SwitchWith404>
		<Route path={`${DASHBOARD_PATH}`} component={withTitleAndLink(Dashboard, { title: t('dashBoard') })} />
		<Route path={`${PROJECTS_PATH}`} component={Projects} />
		<Route path={`${MANAGEMENT_TASKS_PATH}`} component={Tasks} />
		<Route path={`${MANAGEMENT_SETTINGS_PATH}`} component={withTitleAndLink(Settings, { title: t('settings') })} />
	</SwitchWith404>
);

