import classnames from 'classnames';
import { Button } from '@consta/uikit/Button';
import { ContextMenu } from '@consta/uikit/ContextMenu';
import { IconKebab } from '@consta/icons/IconKebab';
import { createRef } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';

@observer
export class ContextMenuButton extends React.Component {
	@observable isOpen = false;
	ref = createRef();

	static defaultProps = {
		getItemDisabled: (item) => item.active,
		getItemOnClick: (item) => item.onClick,
		getItemLeftIcon: (item) => item.iconLeft,
		getItemRightIcon: (item) => item.iconRight,
		buttonIcon: IconKebab,
		buttonForm: 'round',
		buttonView: 'clear',
		buttonOnlyIcon: true,
		items: [],
		size: 'm',
		contextMenuSize: 'm',
		closeAfterItemClick: true,
	};

	open = () => (this.isOpen = true);
	close = () => (this.isOpen = false);

	render() {
		const { items, getItemDisabled, className, getItemOnClick, getItemLeftIcon, contextMenuSize, closeAfterItemClick } = this.props;
		const { buttonForm, buttonView, getItemRightIcon, buttonIcon, buttonOnlyIcon, size } = this.props;
		const _className = classnames(className, 'ContextMenuButton');
		const showContextMenu = items.length > 0;

		return (
			<div className={_className}>
				<Button
					ref={this.ref}
					iconLeft={buttonIcon}
					onlyIcon={buttonOnlyIcon}
					form={buttonForm}
					view={buttonView}
					onClick={this.open}
					size={size}
				/>
				{showContextMenu && (
					<ContextMenu
						isOpen={this.isOpen}
						getItemOnClick={getItemOnClick}
						items={items}
						getItemDisabled={getItemDisabled}
						getItemLeftIcon={getItemLeftIcon}
						getItemRightIcon={getItemRightIcon}
						anchorRef={this.ref}
						direction='downStartLeft'
						onClickOutside={this.close}
						onEsc={this.close}
						onItemClick={closeAfterItemClick ? this.close : undefined}
						size={contextMenuSize}
					/>
				)}
			</div>
		);
	}
}
