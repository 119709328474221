import store from '@appStore';
import { Theme, useTheme } from '@consta/uikit/Theme';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { THEMES } from '@app/config/theme';

export const ConstaTheme = observer(({ children }) => {
	const { themeClassNames } = useTheme();

	//! это адаптация нужна чтобы у Popover и Popup(в том числе селекты, дейтпикеры и их порталы) smartplatform/ui  работали переменные из консты
	//! этот код нужно удалить(весь useEffect) после того как выпилим все старые компоненты из smartplatform/ui
	useEffect(() => {
		let bodyClassName = ['Theme'];
		bodyClassName.push(store.local.theme === 'DEFAULT' ? 'default-theme' : 'dark-theme');
		for (const key of Object.keys(themeClassNames)) {
			if (key === 'color') {
				const MAP = {
					DEFAULT: 'Theme_color_gpnDefault',
					DARK: 'Theme_color_gpnDark',
					DISPLAY: 'Theme_color_gpnDisplay',
				};
				bodyClassName.push(MAP[store.local.theme]);
			} else {
				let className = themeClassNames[key]?.split(' ')[1];
				className && bodyClassName.push(className);
			}
		}
		document.body.className = bodyClassName.join(' ');
	}, [store.local.theme]);

	// получение пресеты темы
	let themeCode = store.local.theme;
	if (!Object.keys(THEMES).includes(themeCode)) {
		themeCode = 'DEFAULT';
	}

	return <Theme preset={THEMES[themeCode]}>{children}</Theme>;
});
