import React from 'react';
import { Route } from 'react-router-dom';
import loadable from '@loadable/component';
import { CONTRAGENTS_PATH, CONTRAGENTS_SETTINGS_PATH } from './constants';
import { withTitleAndLink } from 'client/tools/hoc/withTitleAndLink';
import { SwitchWith404 } from '@widgets/PageNotFound/SwitchWith404';
import t from 'i18n';

const Settings = loadable(() => import('./settings/Settings'));
const Contragents = loadable(() => import('./contragents'));

export default () => (
	<SwitchWith404>
		<Route path={CONTRAGENTS_PATH} component={Contragents} />
		<Route path={CONTRAGENTS_SETTINGS_PATH} component={withTitleAndLink(Settings, { title: t('settings') })} />
	</SwitchWith404>
);

