import { Tag } from '@consta/uikit/Tag';

export const Label = ({ label, onCancel, size = 'm' }) => {
	if (!label) return null;
	const { name, color } = label;
	const mode = onCancel ? 'cancel' : 'check';

	const style = {};
	if (color) {
		style['--tag-base-group-color'] = color;
	}

	return <Tag mode={mode} group={1} label={name} style={style} onCancel={onCancel} size={size} />;
};
