import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Input, NumberInput } from '@smartplatform/ui';
import { Checkbox } from '@smartplatform/consta/ui/Checkbox';
import { Button } from 'components';
import classNames from 'classnames';

import './style.scss';

@observer
export class FeatureValueSelector extends React.Component {
	@observable value = null;

	componentDidMount() {
		const { selectedFilters, feature, isLastFeature, setLastValueForTheFirstTime } = this.props;
		this.value = selectedFilters[feature.id];
		isLastFeature && setLastValueForTheFirstTime(this.value);
	}

	componentDidUpdate(prevProps, prevState) {
		const { selectedFilters, feature } = this.props;

		this.value = selectedFilters[feature.id];
	}

	setBooleanValue = async (value) => {
		if (value || value === false) {
			this.value = value;
			this.onSelect();
		}
	};

	setListValue = async (value) => {
		if (this.value.id !== value) {
			this.value = value;

			this.onSelect();
		}
	};

	onSelect = () => {
		const { product, feature } = this.props;

		this.props.onSelect({ product, feature, value: this.value });
	};

	preventPropagation = (e) => {
		e.preventDefault();
		e.stopPropagation();
	};

	render() {
		const { feature, enableFeatureValueIds, activeVariantsFeatureValues } = this.props;

		let content = '-';

		if (!activeVariantsFeatureValues) return null;

		if (feature.type === 'string') {
			content = <Input value={this.value} onChange={this.setStringValue} />;
		} else if (feature.type === 'number') {
			content = <NumberInput value={this.value} onChange={this.setNumberValue} />;
		} else if (feature.type === 'boolean') {
			content = (
				<>
					<Checkbox value={this.value} onChange={this.setBooleanValue} />
					&nbsp;
					{feature.name}
				</>
			);
		} else if (feature.type === 'list') {
			content = (
				<>
					{feature.featureValues().map((value, i) => {
						if (enableFeatureValueIds?.has(value.id)) {
							const isSelected = this.value && this.value.id === value.id;
							const btnVariant = isSelected ? 'info' : 'default';
							const isDisabled = !activeVariantsFeatureValues.has(value.id);

							return (
								<Button
									key={value.id}
									onClick={() => this.setListValue(value)}
									variant={btnVariant}
									size='sm'
									className={classNames({
										'not-selected': !isSelected,
										'pseudo-disabled': isDisabled,
									})}
								>
									{value.name}
								</Button>
							);
						}
					})}
				</>
			);
		}

		return (
			<div className='feature-values-block'>
				{feature?.name}
				<div className='feature-value-selector' onClick={this.preventPropagation}>
					{content}
				</div>
			</div>
		);
	}
}

