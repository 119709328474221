import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import t from 'i18n';
import { Button } from './Button';
import { faFilter } from '@fortawesome/free-solid-svg-icons';

import './button.scss';

const makeButton =
	({ className, ...rest }) =>
	(props) =>
		<Button {...rest} {...props} className={classNames(props.className, className)} />;

export const CancelButton = (props) => {
	const Button = makeButton({ className: 'cancel-button', variant: 'default' });
	return <Button {...props} text={t('cancel')} />;
};

export const FiltersButton = (props) => {
	const Button = makeButton({
		variant: 'default',
		startIcon: <FontAwesomeIcon icon={faFilter} />,
		className: 'filter-button',
	});
	return <Button {...props} text={t('filter.plural')} />;
};

export { Button } from './Button';
export { DeleteButton } from './DeleteButton';

export { AddButton } from './AddButton';
