import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { throttle, debounce } from 'lodash';

import { Button, Input, NumberInput, RecordSelect } from '@smartplatform/ui';
import { Checkbox } from '@smartplatform/consta/ui/Checkbox';
import store from 'client/store';

@observer
export class ProductFeatureValue extends React.Component {
	@observable value = null;
	@observable possibleValues = [];

	constructor(props) {
		super(props);
		this.saveValue = debounce(this.saveValue, 500, { trailing: true });
		this.init();
	}

	init = async () => {
		const { product, feature } = this.props;
		if (product.id) {
			const values = await store.model.ProductFeatureValue.find({
				where: {
					productId: product.id,
					featureId: feature.id,
				},
				include: ['featureValue'],
			});
			this.value = values.length > 0 ? values[0] : null;
		}

		if (feature.type === 'list') {
			this.possibleValues = await store.model.FeatureValue.find({
				where: { featureId: feature.id },
				order: 'order asc',
			});
		}
	};

	setStringValue = async (value) => {
		await this.checkValue();
		this.value.stringValue = value;
		await this.saveValue();
	};

	setNumberValue = async (value) => {
		await this.checkValue();
		this.value.numberValue = value;
		await this.saveValue();
	};

	setBooleanValue = async (value) => {
		await this.checkValue();
		this.value.booleanValue = value;
		await this.saveValue();
	};

	setListValue = async (value) => {
		await this.checkValue();
		this.value.featureValueId = value.id;
		await this.saveValue();
	};

	checkValue = async () => {
		const { product, feature } = this.props;
		if (!this.value) {
			const newValue = new store.model.ProductFeatureValue();
			newValue.featureId = feature.id;
			newValue.productId = product.id;
			await newValue.save();
			this.value = newValue;
		}
	};

	saveValue = async () => {
		await this.value.save();
		this.props.onChange && this.props.onChange(this.value);
	};

	onListChange = async (value) => {
		await this.checkValue();

		this.value.featureValue = value;
		this.value.featureValueId = value?.id ?? null;
		await this.saveValue();
	};

	render() {
		const { product, feature } = this.props;

		let content = '-';

		if (feature.type === 'string') {
			content = <Input value={this.value?.stringValue || ''} onChange={this.setStringValue} />;
		} else if (feature.type === 'number') {
			content = <NumberInput value={this.value?.numberValue} onChange={this.setNumberValue} />;
		} else if (feature.type === 'boolean') {
			content = (
				<>
					<Checkbox value={this.value?.booleanValue || false} onChange={this.setBooleanValue} />
				</>
			);
		} else if (feature.type === 'list') {
			content = (
				<RecordSelect
					model={store.model.FeatureValue}
					property='name'
					filter={{
						where: { featureId: feature.id },
						order: 'order asc',
					}}
					value={this.value}
					onChange={this.onListChange}
					showValue={this.value?.featureValue?.name ?? '-'}
				/>
			);
		}

		return (
			<div className='feature-value'>
				{feature.name}
				{content}
			</div>
		);
	}
}

