import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Loader } from '@smartplatform/ui';
import { TinyMCEEditor } from '@smartplatform/ui';

import initYoutubePlugin from './plugins/youtube';
import initFilesUploadPlugin from './plugins/files-upload';

import contentCss from '!!file-loader?outputPath=css/tinymce!./content.css';
import contentCssDark from '!!file-loader?outputPath=css/tinymce!./content-dark.css';
import gridCss from '!!file-loader?outputPath=css/tinymce!./grid.css';
import store from 'client/store';

import './style.scss';

const RESIZE_IMAGE = 885;

@observer
export default class Editor extends React.Component {
	static propTypes = {
		value: PropTypes.string,
		filesUploadData: PropTypes.object,
		onChange: PropTypes.func,
		disabled: PropTypes.bool,
		autoFocus: PropTypes.bool,

		/** Высота редактора, может быть числом в пикселях или строкой с css-значением.
		 *  Игнорируется при включенном autoResize. */
		height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

		/** Режим автоматической высоты редактора. */
		autoResize: PropTypes.bool,

		/** Минимальная Высота редактора, число в пикселях.
		 *  Игнорируется при выключенном autoResize. */
		minHeight: PropTypes.number,

		/** Максимальная Высота редактора, число в пикселях.
		 *  Игнорируется при выключенном autoResize. */
		maxHeight: PropTypes.number,

		initialValue: PropTypes.string,
		textareaName: PropTypes.string,
		mediaModel: PropTypes.any,
		mediaProperty: PropTypes.string,
		documentBaseUrl: PropTypes.string,
		acceptFiles: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
		onInit: PropTypes.func,
		menubar: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
		toolbar: PropTypes.string,
		beforeSave: PropTypes.func,
		mentions: PropTypes.array,
	};

	static defaultProps = {
		disabled: false,
		acceptFiles: ['image/jpeg', 'image/png'],
		mediaProperty: 'filename',
		autoResize: true,
		autoFocus: false,
	};

	@observable isLoading = false;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.props.beforeSave && this.props.beforeSave(this.beforeSave);
		initYoutubePlugin(); // вызывать в конструкторе, до загрузки tinymce
		if (this.props.filesUploadData) initFilesUploadPlugin(props.filesUploadData);

		this.isLoading = true;
		// const module = await import('@smartplatform/ui/src/components/editor/tinymce/Editor');
		// const Editor = module.default;
		// this.tinyMceEditor = Editor;
		this.isLoading = false;
	};

	handleProgress = (event) => {
		// this.setState({ progress: Math.round(event.percent) });
	};

	handleEnd = (error, result, media, success, failure, sizeMedia) => {
		if (error) {
			console.warn('upload error:', error);
			failure(error.message);
		} else {
			let url = media.downloadFile(this.props.mediaProperty);
			if (sizeMedia.width > RESIZE_IMAGE) {
				url += `?image=[{"resize":{"width":${RESIZE_IMAGE}}]`;
			}

			success(url);
			// success({ url, media });
			// this.setState({ url, error: null });
			// this.mediaInstance && this.mediaInstance.init();
		}
	};

	uploadHandler = async (blobInfo, success, failure) => {
		const file = blobInfo.blob();
		const filename = blobInfo.filename();
		const sizeMedia = await createImageBitmap(file);
		const media = new this.props.mediaModel();
		media[this.props.mediaProperty] = filename;
		media.inline = true;
		media.save().then(() => {
			media
				.uploadFile(this.props.mediaProperty, file)
				.on('progress', (event) => this.handleProgress(event))
				.end((error, result) => this.handleEnd(error, result, media, success, failure, sizeMedia));
		});
	};

	beforeSave = async (record) => {
		if (this.editor) {
			await this.uploadImages(this.editor);
		}
	};

	onInit = (editor) => {
		this.editor = editor;
		if (this.props.autoFocus) {
			editor.focus();
			editor.selection.select(editor.getBody(), true);
			editor.selection.collapse(false);
		}
		this.props.onInit && this.props.onInit(editor);
	};

	uploadImages = (editor) =>
		new Promise((resolve) => {
			editor.uploadImages((success) => {
				resolve(success);
			});
		});

	render() {
		const { onInit, ...rest } = this.props;

		if (this.isLoading) return <Loader />;

		// const TinyMCEEditor = this.tinyMceEditor;

		const mainCss = store.local.theme === 'DEFAULT' ? contentCss : contentCssDark;

		return (
			<TinyMCEEditor
				plugins={this.props.filesUploadData ? 'sp-files' : undefined}
				contentCss={[gridCss, mainCss]}
				automaticUploads={true}
				imagesUploadHandler={this.props.mediaModel ? this.uploadHandler : undefined}
				onInit={this.onInit}
				mentions={this.props.mentions}
				menubar=''
				toolbar='styleselect | bold italic | alignleft aligncenter alignjustify alignright | numlist bullist | blockquote | code | link image media | table'
				{...rest}
				contextMenu='copy | paste | link'
			/>
		);
	}
}

