import { set } from 'lodash';

export const parseConfig = (configRecords) => {
	const parsedConfig = {};
	for (let config of configRecords) {
		const hiddenValue = config.hiddenValue === 'true' ? true : config.hiddenValue === 'false' ? false : config.hiddenValue;
		const value = config.value === 'true' ? true : config.value === 'false' ? false : config.value;
		set(parsedConfig, config.code, hiddenValue || value);
	}
	return parsedConfig;
};
