import React from 'react';
import { User, TruncateText } from 'components';
import { Label } from '@features/Label';
import './style.scss';
import { Popover } from '@smartplatform/ui';

const defautColor = 'grey';

export const renderUser = (user, size = 24, text) => {
	return user ? <User user={user} size={size} text={text} key={user.id} /> : '-';
};

export const renderStatus = (boardList) =>
	boardList && (
		<div className='render-status'>
			<span className='color' style={{ background: boardList.color || defautColor }} />
			<TruncateText>{boardList.name}</TruncateText>
		</div>
	);

export const renderNameOrDescription = (record) => record && <span>{record.name || record.description}</span>;

export const renderStatusPopover = (status) =>
	status && (
		<Popover content={status.name} position='top'>
			<span className='color popover-color' style={{ background: status.color || defautColor }} />
		</Popover>
	);

export const renderProject = (project) =>
	project && (
		<div className='render-project'>
			<span className='color' style={{ background: project.projectList?.color || defautColor }} /> {project.name}
		</div>
	);

export const renderOrder = (order) =>
	order && (
		<div className='render-project'>
			<span className='color' style={{ background: order.list?.color || defautColor }} /> {order.description}
		</div>
	);

export const renderList = (list) => (
	<div className='render-order'>
		<span className='color' style={{ background: list.color }} /> {list.name}
	</div>
);

export const renderBankAccount = (bankAccount) => bankAccount && <div>{bankAccount.name || bankAccount.checkingAccount}</div>;

export const renderMoney = (sum = 0, fixed = 0, local = 'ru-RU') => {
	sum = Number(sum);
	if (!sum) sum = 0;
	console.assert(typeof sum === 'number', 'wrong renderMoney sum argument type');
	if (!Number.isInteger(sum)) sum = Number(sum.toFixed(fixed)).toLocaleString();
	return sum;
};
export const renderPlaceholder = (placeholder) => placeholder && <span className='placeholder'>{placeholder}</span>;

export const renderLabelItem = (label, onCancel) => <Label label={label} onCancel={onCancel} />;

export const renderCurrency = ({ shortName, name } = {}) => {
	if (!shortName && !name) return;
	return [shortName, name].filter(Boolean).join(' - ');
};
