import React from 'react';
import classNames from 'classnames';
import { ToolbarCreateButton } from './ToolbarCreateButton';
import { ToolbarCount } from './ToolbarCount';
import { ToolbarSearch } from './ToolbarSearch';
import { ToolbarUserSelect } from './ToolbarUserSelect';
import { ToolbarButtonGroup } from './ToolbarButtonGroup';
import { ToolbarListSelect } from './ToolbarListSelect';
import { ToolbarItemsSelect } from './ToolbarItemsSelect';
import { ToolbarTop } from './ToolbarTop';
import { ToolbarRightSide } from './ToolbarRightSide';
import { ToolbarLeftSide } from './ToolbarLeftSide';
import { ToolbarBottom } from './ToolbarBottom';
import { ToolbarManySelect } from 'components/toolbar/ToolbarManySelect';
import './toolbar.scss';
import { ToolbarPrevNextSwitcher } from 'components/toolbar/ToolbarPrevNextSwitcher';
import { ToolbarAvatarGroup } from './ToolbarAvatarGroup';
import { ToolbarRecordSelect } from './ToolbarRecordSelect';

export const Toolbar = ({ children, className }) => <div className={classNames('composite-toolbar', className)}>{children}</div>;

Toolbar.CreateButton = ToolbarCreateButton;
Toolbar.Count = ToolbarCount;
Toolbar.Search = ToolbarSearch;
Toolbar.AvatarGroup = ToolbarAvatarGroup;
Toolbar.UserSelect = ToolbarUserSelect;
Toolbar.ButtonGroup = ToolbarButtonGroup;
Toolbar.ListSelect = ToolbarListSelect;
Toolbar.ItemsSelect = ToolbarItemsSelect;
Toolbar.Top = ToolbarTop;
Toolbar.Bottom = ToolbarBottom;
Toolbar.RightSide = ToolbarRightSide;
Toolbar.LeftSide = ToolbarLeftSide;
Toolbar.ManySelect = ToolbarManySelect;
Toolbar.PrevNextSwitcher = ToolbarPrevNextSwitcher;
Toolbar.RecordSelect = ToolbarRecordSelect;
